import { ReactElement } from 'react';
import CcxComponentProps from '../../core/CcxComponent';
import { Button, notification } from 'antd';
import DbParameterGroupService from '../../services/DbParameterGroupService';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import styles from './DuplicateDbParameters.module.less';
import { CopyOutlined } from '@ant-design/icons';

interface Props extends CcxComponentProps {
    buttonText?: string;
    className?: string;
    refresh?: () => void;
    record?: any;
}

export type UrlProps = {
    projectUuid: string;
};

function DuplicateDbParameters({
    buttonText = 'Duplicate',
    testId = 'DuplicateDbParameters',
    refresh,
    record,
}: Props): ReactElement {
    const onSubmit = async () => {
        const data = {
            name: `${record.name}_COPY`,
            database_vendor: record.databaseVendor,
            database_version: record.databaseVersion,
            database_type: record.databaseType,
            description: record.description,
            parameters: record.dbParameters,
        };

        try {
            await DbParameterGroupService.createDbParameterGroup(data);
            notification.open({
                message: 'DB Parameter Group Duplicated',
                description:
                    'Database parameter group has been duplicated successfully.',
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });
            if (refresh) {
                refresh();
            }
        } catch (error) {
            notification.open({
                message: 'Failed to Create DB Parameter Group',
                description: `An error occurred while creating the database parameter group: ${error}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
            console.error(error);
        }
    };

    return (
        <Button
            onClick={onSubmit}
            type={'link'}
            className={styles.DuplicateDbParametersPopoverButton}
            data-testid={testId}
            icon={<CopyOutlined />}
        >
            {buttonText}
        </Button>
    );
}

export default DuplicateDbParameters;
