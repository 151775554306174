import { StopOutlined } from '@severalnines/frontend_hub/libs/icons';
import React from 'react';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

const CcxIconStopOutlined = ({ testId = 'CcxIconStopOutlined' }: Props) => {
    return <StopOutlined data-testid={testId} />;
};

export default CcxIconStopOutlined;
