export type DbParameter = {
    [key: string]: string;
};

export type DbParameterGroupProps = {
    uuid: string;
    name: string;
    user_id: string;
    database_vendor: string;
    database_version: string;
    database_type: string;
    description: string;
    created_at: string;
    updated_at: string;
    db_parameters: DbParameter;
    data_stores: any;
};

export type DbParameterGroupInterface = {
    uuid: string;
    name: string;
    userId: string;
    databaseVendor: string;
    databaseVersion: string;
    databaseType: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    dbParameters: DbParameter;
    dataStores: any;
};

export class DbParameterGroup implements DbParameterGroupInterface {
    readonly uuid: string;
    readonly name: string;
    readonly userId: string;
    readonly databaseVendor: string;
    readonly databaseVersion: string;
    readonly databaseType: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly dbParameters: DbParameter;
    readonly dataStores: any;
    readonly description: string;

    constructor(props: DbParameterGroupProps) {
        this.uuid = props.uuid;
        this.name = props.name;
        this.userId = props.user_id;
        this.databaseVendor = props.database_vendor;
        this.databaseVersion = props.database_version;
        this.databaseType = props.database_type;
        this.createdAt = props.created_at;
        this.updatedAt = props.updated_at;
        this.dbParameters = props.db_parameters;
        this.dataStores = props.data_stores;
        this.description = props.description;
    }
}
