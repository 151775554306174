import React from 'react';
import { BellOutlined } from '@severalnines/frontend_hub/libs/icons';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

const CcxIconBellOutlined = ({ testId }: Props) => {
    return <BellOutlined data-testid={testId || 'CcxIconBellOutlined'} />;
};

export default CcxIconBellOutlined;
