import React from 'react';
import { DownOutlined } from '@severalnines/frontend_hub/libs/icons';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

const CcxIconDownOutlined = ({ testId }: Props) => {
    return <DownOutlined data-testid={testId || 'CcxIconDownOutlined'} />;
};

export default CcxIconDownOutlined;
