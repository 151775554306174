export type CloudProviderRegionClassProps = {
    code: string;
    name: string;
    country_code?: string;
    continent_code?: string;
    city: string;
    display_code?: string;
    countryCode?: string;
    continentCode?: string;
    displayCode?: string;
    preferredDisplayCode?: string;
};

export interface CloudProviderRegionInterface {
    code: string;
    name: string;
    countryCode: string;
    continentCode: string;
    displayCode: string;
    city: string;
}

export default class CloudProviderRegion
    implements CloudProviderRegionInterface
{
    readonly code: string;
    readonly name: string;
    readonly countryCode: string;
    readonly continentCode: string;
    readonly city: string;
    readonly displayCode: string;

    constructor(props: CloudProviderRegionClassProps) {
        const map: { [key: string]: (keyof CloudProviderRegionClassProps)[] } =
            {
                countryCode: ['country_code', 'countryCode'],
                continentCode: ['continent_code', 'continentCode'],
                displayCode: ['display_code', 'displayCode'],
            };

        this.code = props.code;
        this.name = props.name;
        this.city = props.city;

        this.countryCode = this.getMappedValue(
            props,
            map.countryCode
        ).toLowerCase();
        this.continentCode = this.getMappedValue(props, map.continentCode);
        this.displayCode = this.getMappedValue(props, map.displayCode);
    }

    private getMappedValue(
        props: CloudProviderRegionClassProps,
        keys: (keyof CloudProviderRegionClassProps)[]
    ): string {
        for (const key of keys) {
            const value = props[key];
            if (value) {
                return value;
            }
        }
        return '';
    }
}
