import { CheckCircleOutlined } from '@severalnines/frontend_hub/libs/icons';
import React from 'react';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {
    twoToneColor?: string;
}

const CcxIconCheckCircle = ({
    testId = 'CcxIconCheckCircle',
    twoToneColor,
}: Props) => <CheckCircleOutlined data-testid={testId} color={twoToneColor} />;

export default CcxIconCheckCircle;
