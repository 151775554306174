import { useCallback, useEffect, useState } from 'react';
import DbParameterGroupService from '../../services/DbParameterGroupService';

const useDbParametersGroup = () => {
    const [dbParameters, setDbParameters] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchDbParameters = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response =
                await DbParameterGroupService.getDbParameterGroups();
            setDbParameters(response);
        } catch (err) {
            setError('Failed to fetch DB parameters');
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchDbParameters();
    }, [fetchDbParameters]);

    return { dbParameters, loading, error, refresh: fetchDbParameters };
};

export default useDbParametersGroup;
