import React, { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../../core/CcxComponent';
import Service from '../../../../types/Service';
import styles from './ServiceResources.module.less';
import { Col, Row, Tooltip } from 'antd';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import InstanceSize from '../../../../types/InstanceSize';
import useContentInstanceSizes from '../../../../core/hooks/useContentInstanceSizes';
import LazyLoader from '../../../LazyLoader';
import CcxLogo from '../../../ccx/common/CcxLogo';
import { ApartmentOutlined } from '@severalnines/frontend_hub/libs/icons';
import { CcxIconCpu, CcxIconDisk } from '../../../ccx/icons/Icons';
import { useAppSelector } from '../../../../redux/hooks';
interface Props extends CcxComponentProps {
    service?: Service;
    dataStore?: DeploymentsItem;
    type?: string;
}

function ServiceResources({
    dataStore,
    service,
    type = 'db',
}: Props): ReactElement {
    const [instanceSize, setInstanceSize] = useState<InstanceSize[]>();

    const cloudProvider = service && service.getCloudProvider();
    const { instanceSizes, loading } = useContentInstanceSizes(cloudProvider);

    const { deploymentOptions } = useAppSelector(
        (state) => state.deploymentOptions
    );

    useEffect(() => {
        if (service && instanceSizes) {
            const instance = instanceSizes[0]?.production?.filter(
                (s: InstanceSize) => s.type === service?.getInstanceType()
            );
            setInstanceSize(instance);
        }
    }, [instanceSizes, service]);

    const dbInfo = dataStore && (
        <div className={styles.ServiceResourcesSection}>
            <CcxLogo logo={dataStore.getDbIconName()} size="xsmall" />
            <div>{dataStore.getDbVendorName()}</div>
            <div className={styles.DatabaseInformationVersion}>
                {dataStore.db.dbVersion}
            </div>
        </div>
    );

    const lbInfo = service && (
        <div className={styles.ServiceResourcesSection}>
            <ApartmentOutlined />
            <div>{service.getElegantNodeType()}</div>
            <div className={styles.DatabaseInformationVersion}>
                {service.getNodeVersion()}
            </div>
        </div>
    );

    const instance_Size =
        instanceSize && instanceSize[0] ? (
            <div className={styles.ServiceResourcesInstance}>
                <div className={styles.ServiceResourcesSection}>
                    <CcxIconCpu />
                    {instanceSize[0]?.name || ''}
                </div>
                <div className={styles.ServiceResourcesDetail}>
                    <Tooltip title="CPU">
                        {` (${instanceSize[0]?.cpu || ''} Cores, `}
                    </Tooltip>
                    <Tooltip title="RAM">
                        {`${instanceSize[0]?.ram || ''} GB)`}
                    </Tooltip>
                </div>
            </div>
        ) : (
            'N/A'
        );

    const volumeInfo = (
        <div className={styles.ServiceResourcesInstance}>
            <div className={styles.ServiceResourcesSection}>
                <CcxIconDisk />
                {`${service?.getDiskType() || ''}`}
            </div>
            {dataStore?.hasStorage() && (
                <Tooltip
                    className={styles.ServiceResourcesDetail}
                    title="Storage"
                >
                    {`(${service?.getDiskSizeInfo(deploymentOptions) || ''}GB)`}
                </Tooltip>
            )}
        </div>
    );

    const availabilityZone = service?.getAvailabilityZone() && (
        <Tooltip title="Availability Zone" placement="left">
            <div className={styles.ServiceResourcesSection}>
                <ApartmentOutlined /> AZ: {service?.getAvailabilityZone()}
            </div>
        </Tooltip>
    );

    return (
        <>
            {loading ? (
                <LazyLoader type="row" rows={1} />
            ) : (
                instanceSize && (
                    <div className={styles.ServiceResourcesRow}>
                        <Row>
                            {dbInfo && (
                                <Col span={10}>
                                    {type === 'db' ? dbInfo : lbInfo}
                                </Col>
                            )}
                            {instance_Size && (
                                <Col span={14}>{instance_Size}</Col>
                            )}
                            {availabilityZone && (
                                <Col span={10}>{availabilityZone}</Col>
                            )}
                            {volumeInfo && <Col span={14}>{volumeInfo}</Col>}
                        </Row>
                    </div>
                )
            )}
        </>
    );
}

export default ServiceResources;
