import { InfoCircleFilled } from '@ant-design/icons';
import { Popover, Space, Row, Col, Button, Tooltip } from 'antd';
import { useState } from 'react';
import styles from './DeleteDbParametersPopover.module.less';
import CcxIconInfoCircleOutlined from '../ccx/icons/CcxIconInfoCircleOutlined';

interface UpdateDbParametersPopoverProps {
    handleCancel: () => void;
    handleOk: () => void;
    testId?: string;
}

const UpdateDbParametersPopover = ({
    handleCancel,
    handleOk,
    testId = 'UpdateDbParametersPopover',
}: UpdateDbParametersPopoverProps) => {
    const [openPopover, setOpenPopover] = useState(false);

    return (
        <Popover
            open={openPopover}
            placement="left"
            content={
                <>
                    <Row justify={'end'} className={styles.PopoverContentRow}>
                        <Col span={2}>
                            <InfoCircleFilled
                                className={styles.ExclamationCircleFilledColor}
                            />
                        </Col>
                        <Col span={22}>
                            <div>Are you sure?</div>
                            <div>
                                The datastore configuration will be applied to
                                all associated data stores.
                            </div>
                        </Col>
                    </Row>

                    <Row justify={'end'} className={styles.PopoverActionsRow}>
                        <Space>
                            <Button type="primary" onClick={() => handleOk()}>
                                Yes
                            </Button>
                            <Button onClick={() => setOpenPopover(false)}>
                                No
                            </Button>
                        </Space>
                    </Row>
                </>
            }
            trigger="click"
            onOpenChange={setOpenPopover}
        >
            <Button type="primary" onClick={() => setOpenPopover(!openPopover)}>
                Update
            </Button>
        </Popover>
    );
};

export default UpdateDbParametersPopover;
