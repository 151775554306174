import { Modal, Menu, Button, Dropdown, notification } from 'antd';
import { ReactElement } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import AuthorizationService from '../../../services/AuthorizationService';
import Credentials from '../../../types/Credentials';
import AppConfirmDialog from '../../AppConfirmDialog';
import CcxContextualMenuIcon from '../../ccx/icons/CcxContextualMenuIcon';
import CcxIconCheckCircle from '../../ccx/icons/CcxIconCheckCircle';
import CcxIconCloseCircleTwoTone from '../../ccx/icons/CcxIconCloseCircleTwoTone';
import CcxIconInfoCircleTwoTone from '../../ccx/icons/CcxIconInfoCircleTwoTone';

interface Props extends CcxComponentProps {
    credentials: Credentials;
    refresh: Function;
}

export function CredentialsActions({
    testId = 'CredentialsActions',
    credentials,
    refresh,
}: Props): ReactElement {
    const enableCrendentials = () => {
        return new Promise(async (resolve, reject) => {
            notification.open({
                message: 'Enable Credentials',
                description: 'Credentials will be enabled',
                icon: <CcxIconInfoCircleTwoTone />,
            });

            Modal.destroyAll();

            try {
                await AuthorizationService.enableCredentials(
                    credentials.clientId
                );

                refresh && (await refresh());

                notification.open({
                    message: 'Enable Credentials',
                    description: 'Credentials successfully enabled',
                    icon: <CcxIconCheckCircle />,
                });

                resolve(null);
            } catch (e) {
                notification.open({
                    message: 'Enable Credentials',
                    description: `There was an error enabling the Credentials. ${e}`,
                    icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                });

                reject();
            }
        });
    };

    const disableCrendentials = () => {
        return new Promise(async (resolve, reject) => {
            notification.open({
                message: 'Disable Credentials',
                description: 'Credentials will be disabled',
                icon: <CcxIconInfoCircleTwoTone />,
            });

            Modal.destroyAll();

            try {
                await AuthorizationService.disableCredentials(
                    credentials.clientId
                );

                refresh && (await refresh());

                notification.open({
                    message: 'Disable Credentials',
                    description: 'Credentials successfully disabled',
                    icon: <CcxIconCheckCircle />,
                });

                resolve(null);
            } catch (e) {
                notification.open({
                    message: 'Disable Credentials',
                    description: `There was an error disabling the Credentials. ${e}`,
                    icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                });

                reject();
            }
        });
    };

    const removeCrendentials = () => {
        return new Promise(async (resolve, reject) => {
            notification.open({
                message: 'Remove Credentials',
                description: 'Credentials will be removed',
                icon: <CcxIconInfoCircleTwoTone />,
            });

            Modal.destroyAll();

            try {
                await AuthorizationService.removeCredentials(
                    credentials.clientId
                );

                refresh && (await refresh());

                notification.open({
                    message: 'Remove Credentials',
                    description: 'Credentials successfully removed',
                    icon: <CcxIconCheckCircle />,
                });

                resolve(null);
            } catch (e) {
                notification.open({
                    message: 'Remove Credentials',
                    description: `There was an error removing the Credentials. ${e}`,
                    icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                });

                reject();
            }
        });
    };

    const revokeCredentials = () => {
        return new Promise(async (resolve, reject) => {
            notification.open({
                message: 'Revoke Credentials',
                description: 'Credentials will be revoked',
                icon: <CcxIconInfoCircleTwoTone />,
            });

            Modal.destroyAll();

            try {
                await AuthorizationService.revokeCredentials(
                    credentials.clientId
                );

                refresh && (await refresh());

                notification.open({
                    message: 'Revoke Credentials',
                    description: 'Credentials successfully revoked',
                    icon: <CcxIconCheckCircle />,
                });

                resolve(null);
            } catch (e) {
                notification.open({
                    message: 'Revoke Credentials',
                    description: `There was an error revoking the Credentials. ${e}`,
                    icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                });

                reject();
            }
        });
    };

    const menu = (
        <Menu>
            {credentials.canBeDisabled() && (
                <Menu.Item
                    key="disableCredentials"
                    onClick={disableCrendentials}
                >
                    Disable
                </Menu.Item>
            )}

            {credentials.canBeEnabled() && (
                <Menu.Item
                    key="disableCredentials"
                    onClick={enableCrendentials}
                >
                    Enable
                </Menu.Item>
            )}

            {credentials.canBeRevoked() && (
                <Menu.Item key="revokeCredentials">
                    <AppConfirmDialog
                        critical={true}
                        onOk={revokeCredentials}
                        label="Revoke"
                        content="The selected Credentials will be revoked."
                    />
                </Menu.Item>
            )}

            {credentials.canBeRemoved() && (
                <Menu.Item key="removeCrendentials">
                    <AppConfirmDialog
                        critical={true}
                        onOk={removeCrendentials}
                        label="Remove"
                        content="The selected Credentials will be removed forever."
                    />
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <Dropdown
            data-testid={`${testId}MenuDropdown`}
            overlay={menu}
            trigger={['click']}
        >
            <Button
                data-testid={`${testId}MenuButton`}
                icon={
                    <CcxContextualMenuIcon testId={`${testId}MenuButtonIcon`} />
                }
            ></Button>
        </Dropdown>
    );
}
