import { config } from 'process';
import { DbParameterGroup, DbParameterGroupProps } from './DbParameterGroup';

export type DbParameter = {
    [key: string]: string;
};

export type Metadata = {
    currentPage: number;
    pageSize: number;
    firstPage: number;
    lastPage: number;
    totalRecords: number;
};

export type DbParameterGroupsInterface = {
    readonly pagination: Metadata;
    readonly parameterGroups: DbParameterGroup[];
};

export class DbParametersGroup implements DbParameterGroupsInterface {
    readonly parameterGroups: DbParameterGroup[];
    readonly pagination: Metadata;

    constructor(response: any) {
        this.pagination = {
            currentPage: response.metadata.current_page,
            pageSize: response.metadata.page_size,
            firstPage: response.metadata.first_page,
            lastPage: response.metadata.last_page,
            totalRecords: response.metadata.total_records,
        };

        this.parameterGroups = response.parameter_groups.map(
            (group: DbParameterGroupProps) => new DbParameterGroup(group)
        );
    }
}
