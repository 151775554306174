import { ReactElement, useEffect, useRef, useState } from 'react';
import { Button, Popconfirm, Tabs, notification } from 'antd';
import { useHistory, useParams } from 'react-router';
import CcxComponentProps from '../../../../core/CcxComponent';
import styles from './DataStoreSettings.module.less';
import CcxIconCheckCircle from '../../../ccx/icons/CcxIconCheckCircle';
import CcxIconCloseCircleTwoTone from '../../../ccx/icons/CcxIconCloseCircleTwoTone';
import DatabaseConfigurationService, {
    UpdateDatabaseParametersPayload,
} from '../../../../services/DatabaseConfigurationService';
import DataStoreSettingsGeneral from './DatastoreSettingsGeneral';
import DatastoreSettingDbParameters from './DatastoreSettingsDbParamters';
import useDbParameters from '../../../../core/hooks/useDbParameters';
import AppLoading from '../../../AppLoading';
import useDataStoreJobs from '../../../../core/hooks/useDataStoreJobs';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import DbParameters from '../../../../types/DbParameters';
import { Parameter } from '../../../../types/DbParameter';

interface UrlProps {
    dataStoreUuid: string;
    projectUuid: string;
    tab: string;
}

interface Props extends CcxComponentProps {
    currentDeployment?: DeploymentsItem | null | undefined;
}

function DataStoreSettings({
    currentDeployment,
    testId = 'DataStoreSettings',
}: Props): ReactElement {
    const { dataStoreUuid, projectUuid, tab } = useParams<UrlProps>();
    const history = useHistory();
    const [isEditMode, setIsEditMode] = useState(false);
    const { refresh, dbParameters, loading } = useDbParameters(dataStoreUuid);
    const [dbParametersData, setDbParametersData] = useState<DbParameters>();

    useEffect(() => {
        if (dbParameters) setDbParametersData(dbParameters);
    }, [dbParameters]);

    const { refresh: refreshJobs } = useDataStoreJobs({
        dataStoreUuid,
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const valid = formRef.current?.reportValidity();

        if (valid && dbParametersData) {
            try {
                const payload: UpdateDatabaseParametersPayload = {
                    parameters: {},
                };

                dbParametersData.parameters.forEach((param: Parameter) => {
                    payload.parameters[param.name] = {
                        value:
                            typeof param.value === 'boolean'
                                ? String(param.value)
                                : param.value,
                    };
                });

                await DatabaseConfigurationService.updateDatabaseParameters(
                    dataStoreUuid,
                    payload
                );

                refresh && refresh();
                setDbParametersData(undefined);
                setIsEditMode(false);

                refreshJobs();

                notification.open({
                    message: 'Changes are saved successfully',
                    icon: <CcxIconCheckCircle />,
                });
            } catch (error: unknown) {
                const errorMessage =
                    error instanceof Error
                        ? error.message
                        : 'Unknown error occurred';

                notification.open({
                    message: 'Error',
                    description: `An error occurred while processing the request: ${errorMessage}`,
                    icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                });
            }
        }
    };

    const onChange = (name: string, value: string | boolean) => {
        if (dbParametersData) {
            let tempDbParametersData = JSON.parse(
                JSON.stringify(dbParametersData)
            );

            // Find the index of the parameter with the matching name
            const key = tempDbParametersData?.parameters?.findIndex(
                (param: Parameter) => param.name === name
            );

            if (key !== -1) {
                // If a parameter with the matching name is found, update its value
                tempDbParametersData.parameters[key].value = value;
            }

            setDbParametersData(tempDbParametersData);
        }
    };

    const handleCancel = () => {
        setIsEditMode(false);
        setDbParametersData(undefined);
    };

    const formRef = useRef<HTMLFormElement>(null);

    return !currentDeployment ? (
        <AppLoading />
    ) : (
        <form ref={formRef} onSubmit={handleSubmit}>
            <Tabs
                data-testid={testId}
                activeKey={tab || 'general'}
                onChange={(tabKey: any) => {
                    const tab = tabKey === 'general' ? '' : `/${tabKey}`;
                    history.push(
                        `/projects/${projectUuid}/data-stores/${dataStoreUuid}/settings${tab}`
                    );
                }}
            >
                <Tabs.TabPane
                    tab="General"
                    key="general"
                    style={{ paddingTop: 20 }}
                >
                    <DataStoreSettingsGeneral
                        currentDeployment={currentDeployment}
                    />
                </Tabs.TabPane>

                {dbParameters && dbParameters.parameters?.length > 0 && (
                    <Tabs.TabPane
                        tab="DB Parameters"
                        key="dbParameters"
                        style={{ paddingTop: 20 }}
                    >
                        <DatastoreSettingDbParameters
                            isEditMode={isEditMode}
                            onChange={onChange}
                            dbParameters={dbParameters}
                            loading={loading}
                        />
                    </Tabs.TabPane>
                )}
            </Tabs>
        </form>
    );
}

export default DataStoreSettings;
