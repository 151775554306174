import React from 'react';
import { EllipsisOutlined } from '@severalnines/frontend_hub/libs/icons';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

const CcxContextualMenuIcon = ({ testId = 'CcxContextualMenuIcon' }: Props) => {
    return <EllipsisOutlined data-testid={testId} />;
};

export default CcxContextualMenuIcon;
