import { UserOutlined } from '@severalnines/frontend_hub/libs/icons';
import React from 'react';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

const CcxIconUserOutlined = ({ testId = 'CcxIconUserOutlined' }: Props) => {
    return <UserOutlined data-testid={testId} />;
};

export default CcxIconUserOutlined;
