/**
 * @name BaseDomain
 * @description
 *   BaseDomain is injected from FEAS_ENV.BASE_DOMAIN global variable, if not
 *   defined - process.env.BASE_DOMAIN is used. If both globals are undefined
 *   's9s.io' is used as fallback.
 * @constant
 * @public
 */

declare type Window = {
    FEAS_ENV: Feas;
};

declare const window: Window;

export const BASE_PAGE_HEADING = 'Home';

export const MAX_NODES_AVAILABLE = 5;

export const CDN_URL = 'https://st.s9s.io/ccx/logo/';

export const EXTERNAL_CSS_URL =
    (window.FEAS_ENV || {}).EXTERNAL_CSS_URL ||
    process.env.REACT_APP_EXTERNAL_CSS_URL ||
    undefined;

export const StripePK =
    (window.FEAS_ENV || {}).STRIPE_PK || process.env.REACT_APP_STRIPE_PK || '';

export const ApiBaseDomain =
    (window.FEAS_ENV || {}).BASE_DOMAIN ||
    process.env.REACT_APP_BASE_DOMAIN ||
    's9s.io';

export const AppLogoUrl =
    window?.FEAS_ENV?.REACT_APP_LOGO_URL ||
    'https://st.s9s.io/ccx/logo/ccx-purple.svg';

export const AppFaviconUrl =
    window?.FEAS_ENV?.REACT_APP_FAVICON_URL ||
    'https://st.s9s.io/ccx/favicon/ccx_favicon.png';

export const PricingLink = 'https://severalnines.com/pricing/#ccx';

export const EULALink =
    window?.FEAS_ENV?.EULA_URL ||
    'https://docs.cloudeka.id/documentation/170/eula-service-portal';

export const ToSLink = 'https://severalnines.com/ccx/terms/';

export const PrivacyPolicyLink = 'https://severalnines.com/privacy/';

export const vpcDisabled = window?.FEAS_ENV?.VPC_DISABLED || false;
export const billingDisabled = window?.FEAS_ENV?.BILLING_DISABLED || false;
export const onboardingDisabled =
    window?.FEAS_ENV?.ONBOARDING_DISABLED || false;

export const userMenuDisabled = window?.FEAS_ENV?.USER_MENU_DISABLED || false;

export const volumeSizeInputUnitDisabled =
    window?.FEAS_ENV?.VOLUME_SIZE_INPUT_UNIT_DISABLED || false;

export const deploymentSummaryNetworkVPCDisabled =
    window?.FEAS_ENV?.DEPLOYMENT_SUMMARY_NETWORK_VPC_DISABLED || false;

export const deploymentSummaryNetworkTypeDisabled =
    window?.FEAS_ENV?.DEPLOYMENT_SUMMARY_NETWORK_TYPE_DISABLED || false;

export const deploymentSummaryNetworkAZDisabled =
    window?.FEAS_ENV?.DEPLOYMENT_SUMMARY_NETWORK_AZ_DISABLED || false;

export const deploymentSummaryIOPSDisabled =
    window?.FEAS_ENV?.DEPLOYMENT_SUMMARY_IOPS_DISABLED || false;

export const hideCloudProvider = window?.FEAS_ENV?.HIDE_CLOUD_PROVIDER || false;
export const showNotification =
    window?.FEAS_ENV?.SHOW_NOTIFICATION_BUTTON || false;

export const hideFooter = window?.FEAS_ENV?.HIDE_FOOTER || false;
export const eulaEnabled = window?.FEAS_ENV?.EULA || false;

export const hideHeader = window?.FEAS_ENV?.HIDE_HEADER || false;
export const hideBreadcrumbs = window?.FEAS_ENV?.HIDE_BREADCRUMBS || false;
export const hideFeedback =
    window?.FEAS_ENV?.HIDE_FEEDBACK ||
    process.env.REACT_APP_HIDE_FEEDBACK ||
    false;
export const hideProjectName = window?.FEAS_ENV?.HIDE_PROJECT_NAME || false;

export const usePrivateIPs = window?.FEAS_ENV?.USE_PRIVATE_IPS || false;

// BuildNumber and Build branch are injected by TeamCity during build.
export const BuildNumber = process.env.REACT_APP_BUILD_NUMBER || 0;
export const BuildBranch = process.env.REACT_APP_BRANCH || 'unknown';

// API Endpoints
export const API_VPC = `/api/vpc/api/v1`;
export const API_VPC_V2 = `/api/vpc/api/v2`;
export const API_AUTH = `/api/auth`;
export const API_STAT = `/api/stat/api/v1`;
export const API_QMON = `/api/qmon/api/v1`;
export const API_PROV = `/api/prov/api/v1`;
export const API_PROV_V2 = `/api/prov/api/v2`;
export const API_FIREWALL = `/api/firewall/api/v1`;
export const API_DEPLOYMENT = `/api/deployment/api/v1`;
export const API_DEPLOYMENT_V2 = `/api/deployment/v2`;
export const API_DEPLOYMENT_V3 = `/api/deployment/v3`;
export const API_PROJECT = `/api/project/api/v1`;
export const API_USERDB = `/api/userdb/api/v1`;
export const API_CONTENT = `/api/content/api/v1`;
export const API_BILLING = `/api/billing/api/v1`;
export const API_BACKUP = `/api/backup/api/v1`;
export const API_FEEDBACK = `/api/feedback/api/v1`;
export const API_PROFILES = `/api/profiles/api/v1`;
export const API_ADMIN = `/api/admin`;
export const API_DB_CONFIGURATION = `/api/db-configuration/v1`;
export const API_V2_DEPLOYMENT = `/api/deployment/v2`;
export const API_V2 = '/api/v2';
export const CHANGE_PASSWORD = 'change-password';
export const DB_LOGS = 'dblogs';
export const DB_PARAMETERS_GROUP = 'parameter-groups';
export const APPLY = 'apply';
export const LEGACY = 'legacy';

// Application endpoints
export const AUTH_APP_URL = window?.FEAS_ENV?.AUTH_APP_URL || `/auth`;

export const AUTH_REDIRECT_URL =
    window?.FEAS_ENV?.AUTH_REDIRECT_URL ||
    process.env.REACT_APP_AUTH_REDIRECT_URL;
export const WRONG_GLOBAL_REDIRECT_URL =
    window?.FEAS_ENV?.WRONG_GLOBAL_REDIRECT_URL;
export const GLOBAL_REDIRECT_URL = window?.FEAS_ENV?.GLOBAL_REDIRECT_URL;
export const AUTH_UI_APP_DISABLED =
    (window.FEAS_ENV || {}).AUTH_UI_APP_DISABLED ||
    process.env.REACT_APP_AUTH_UI_APP_DISABLED ||
    false;

export const AUTOSCALE_STORAGE_PERCENTAGE =
    (window.FEAS_ENV || {}).AUTOSCALE_STORAGE_PERCENTAGE ||
    process.env.REACT_APP_AUTOSCALE_STORAGE_PERCENTAGE ||
    0;

export const AUTOSCALE_STORAGE_THRESHOLD =
    (window.FEAS_ENV || {}).AUTOSCALE_STORAGE_THRESHOLD ||
    process.env.REACT_APP_AUTOSCALE_STORAGE_THRESHOLD ||
    0;

export const APP_THEME = process.env.REACT_APP_THEME || 'ccx';

declare type Feas = {
    STRIPE_PK: string;
    BASE_DOMAIN: string;
    BUILD_NUMBER: number;
    BRANCH: string;
    REACT_APP_LOGO_URL: string;
    REACT_APP_FAVICON_URL: string;
    VPC_DISABLED: boolean;
    BILLING_DISABLED: boolean;
    ONBOARDING_DISABLED: boolean;
    USER_MENU_DISABLED: boolean;
    VOLUME_SIZE_INPUT_UNIT_DISABLED: boolean;
    DEPLOYMENT_SUMMARY_NETWORK_VPC_DISABLED: boolean;
    DEPLOYMENT_SUMMARY_NETWORK_TYPE_DISABLED: boolean;
    DEPLOYMENT_SUMMARY_NETWORK_AZ_DISABLED: boolean;
    DEPLOYMENT_SUMMARY_IOPS_DISABLED: boolean;
    HIDE_CLOUD_PROVIDER: boolean;
    HIDE_FOOTER: boolean;
    HIDE_HEADER: boolean;
    HIDE_BREADCRUMBS: boolean;
    EULA: boolean;
    HIDE_FEEDBACK: boolean;
    HIDE_PROJECT_NAME: boolean;
    USE_PRIVATE_IPS: boolean;
    AUTH_APP_URL: string;
    AUTH_REDIRECT_URL: string;
    WRONG_GLOBAL_REDIRECT_URL: string;
    GLOBAL_REDIRECT_URL: string;
    EULA_URL: string;
    AUTH_UI_APP_DISABLED: boolean;
    EXTERNAL_CSS_URL: string;
    AUTOSCALE_STORAGE_THRESHOLD: number;
    AUTOSCALE_STORAGE_PERCENTAGE: number;
    SHOW_NOTIFICATION_BUTTON: boolean;
};
