import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { CloseOutlined } from '@severalnines/frontend_hub/libs/icons';
import {
    Form,
    Space,
    Spin,
    Tabs,
    Radio,
    Button,
    Input,
    Result,
    Popover,
} from 'antd';
import TextFormat from './ccx/common/TextFormat';
import styles from './FeedbackForm.module.less';
import CcxComponentProps from '../core/CcxComponent';
import CcxIconMessageOutlined from './ccx/icons/CcxIconMessageOutlined';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface Props extends CcxComponentProps {
    children: ReactNode;
    placement?: TooltipPlacement;
    visible?: boolean;
    showThanks?: boolean;
    showError?: boolean;
    thanksText?: string;
    errorText?: string;
    feedbackTypes?: any[];
    defaultFeedbackType?: string;
    onVisibleChange?: Function;
    onSubmit?: Function;
    onError?: Function;
    onComplete?: Function;
}

function FeedbackForm({
    children,
    placement = 'topRight',
    visible = false,
    onVisibleChange,
    showThanks = false,
    showError = false,
    thanksText = 'Thank you for your feedback.',
    errorText = 'An error has occurred. Please try again.',
    feedbackTypes = [
        { value: 'feedback', label: 'General feedback' },
        { value: 'feature_request', label: 'Feature request' },
        { value: 'bug', label: 'Bug' },
    ],
    defaultFeedbackType = 'feedback',
    onSubmit,
    onError,
    onComplete,
    testId = 'FeedbackForm',
}: Props): ReactElement {
    const [popoverVisible, setPopoverVisible] = useState(visible);
    const [activeKey, setActiveKey] = useState<any>('1');
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const [form] = Form.useForm();
    const [feedbackType, setFeedbackType] =
        useState<string>(defaultFeedbackType);
    const [feedbackMessage, setFeedbackMessage] = useState<string>('');

    useEffect(() => {
        setPopoverVisible(visible);
    }, [visible]);

    const handlePopoverCloseClick = () => {
        if (activeKey === '3' && onComplete) {
            onComplete();
        }
        if (onVisibleChange) {
            onVisibleChange(false);
        }
        setPopoverVisible(false);
        form.resetFields();
        setActiveKey('1');
        setError(null);
    };

    const handlePopoverVisibleChange = (visible: boolean) => {
        if (onVisibleChange) {
            onVisibleChange(visible);
        }
        setPopoverVisible(visible);
        if (!visible) {
            form.resetFields();
            setActiveKey('1');
            setError(null);
        }
    };

    const handleNextClick = () => {
        setActiveKey('2');
    };

    const handleBackClick = () => {
        setActiveKey('1');
    };

    const handleSendClick = async () => {
        setError(null);
        try {
            await form.validateFields();
            setSubmitting(true);
            onSubmit &&
                (await onSubmit({
                    feedbackType,
                    feedbackMessage,
                }));
            setSubmitting(false);

            if (showThanks) {
                setActiveKey('3');
            } else {
                onComplete && onComplete();
                setPopoverVisible(false);
                onVisibleChange && onVisibleChange(false);
                setActiveKey('1');
            }

            form.resetFields();
        } catch (e) {
            if (showError) {
                setError(errorText);
            }
            if (onError) {
                await onError(e);
            }
            setSubmitting(false);
        }
    };

    const popoverContent = (
        <Spin spinning={submitting} data-testid={testId}>
            <Form
                data-testid={`${testId}Form`}
                form={form}
                initialValues={{
                    feedbackMessage: '',
                    feedbackType: defaultFeedbackType,
                }}
            >
                <Tabs
                    className={styles.FeedbackFormTabs}
                    defaultActiveKey="1"
                    activeKey={activeKey}
                    renderTabBar={() => <div />}
                    data-testid={`${testId}Tabs`}
                >
                    <Tabs.TabPane
                        tab="Feedback type"
                        key="1"
                        data-testid={`${testId}Tab1`}
                    >
                        <div className={styles.FeedbackFormTabContent}>
                            <div className={styles.FeedbackFormBody}>
                                <p>
                                    What kind of feedback do you want to
                                    provide?
                                </p>
                                <Form.Item
                                    name="feedbackType"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select feedback type',
                                        },
                                    ]}
                                    {...{
                                        onChange: (e: any) =>
                                            setFeedbackType(e.target.value),
                                    }}
                                >
                                    <Radio.Group
                                        className={
                                            styles.FeedbackFormRadioGroup
                                        }
                                        data-testid={`${testId}RadioGroup`}
                                    >
                                        <Space direction="vertical">
                                            {feedbackTypes.map(
                                                (t: any, i: number) => (
                                                    <Radio
                                                        data-testid={`${testId}RadioOption${i}`}
                                                        key={t.value}
                                                        value={t.value}
                                                    >
                                                        {t.label}
                                                    </Radio>
                                                )
                                            )}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div className={styles.FeedbackFormFooter}>
                                <Button
                                    data-testid={`${testId}ButtonNext`}
                                    onClick={handleNextClick}
                                    type="primary"
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab="Feedback message"
                        key="2"
                        data-testid={`${testId}Tab2`}
                    >
                        <div className={styles.FeedbackFormTabContent}>
                            <div className={styles.FeedbackFormBody}>
                                <div>
                                    Please provide any feedback you have for the
                                    service:
                                </div>
                                <Form.Item
                                    name="feedbackMessage"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Enter your message',
                                        },
                                    ]}
                                    {...{
                                        onChange: (e: any) =>
                                            setFeedbackMessage(e.target.value),
                                    }}
                                >
                                    <Input.TextArea
                                        className={styles.FeedbackFormTextArea}
                                        data-testid={`${testId}TextArea`}
                                        placeholder="Enter your message here"
                                        autoFocus={true}
                                        rows={4}
                                        showCount={true}
                                        maxLength={20000}
                                    />
                                    {error && (
                                        <TextFormat type="danger">
                                            {errorText}
                                        </TextFormat>
                                    )}
                                </Form.Item>
                            </div>
                            <div className={styles.FeedbackFormFooter}>
                                <Button
                                    data-testid={`${testId}ButtonSend`}
                                    onClick={handleSendClick}
                                    type="primary"
                                    // disabled={form.getFieldValue('feedbackMessage') === ''}
                                >
                                    Send
                                </Button>
                                <Button
                                    data-testid={`${testId}ButtonBack`}
                                    onClick={handleBackClick}
                                >
                                    Back
                                </Button>
                            </div>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab="Thanks"
                        key="3"
                        data-testid={`${testId}Tab3`}
                    >
                        <div className={styles.FeedbackFormTabContent}>
                            <div className={styles.FeedbackFormBody}>
                                <Result
                                    className={styles.FeedbackFormThanks}
                                    data-testid={`${testId}Result`}
                                    status="success"
                                    title="Feedback sent!"
                                    subTitle={thanksText}
                                />
                            </div>
                            <div className={styles.FeedbackFormFooter}>
                                <Button
                                    data-testid={`${testId}ButtonClose`}
                                    onClick={handlePopoverCloseClick}
                                    type="primary"
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </Form>
        </Spin>
    );

    return (
        <Popover
            data-testid={`${testId}Popover`}
            overlayClassName={styles.FeedbackFormPopover}
            placement={placement}
            title={
                <div
                    className={styles.FeedbackFormPopoverTitle}
                    data-testid={`${testId}Title`}
                >
                    <Button
                        className={styles.FeedbackFormPopoverClose}
                        data-testid={`${testId}PopoverButtonClose`}
                        type="link"
                        size="small"
                        onClick={handlePopoverCloseClick}
                    >
                        <CloseOutlined />
                    </Button>
                    <h2>
                        Feedback <CcxIconMessageOutlined />
                    </h2>
                </div>
            }
            content={popoverContent}
            arrowPointAtCenter={false}
            trigger="click"
            open={popoverVisible}
            onOpenChange={handlePopoverVisibleChange}
            destroyTooltipOnHide={true}
        >
            {children}
        </Popover>
    );
}

export default FeedbackForm;
