import React, { ReactElement, useEffect, useState } from 'react';
import { Checkbox, Select, Form, Input, Button, notification } from 'antd';
import styles from './DatastoreSettingsDbParameters.module.less';
import './DatastoreSettingsDbParameters.less';
import AppTable from '../../../AppTable';
import AppLoading from '../../../AppLoading';
import { Type } from '../../../../types/DbParameter';
import { EditOutlined } from '@severalnines/frontend_hub/libs/icons';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import DbParameters from '../../../../types/DbParameters';
import { useAppSelector } from '../../../../redux/hooks';
import ParameterGroupDisplay from './ParameterGroupDisplay';
import AssignGroupModal from './AssignGroupModal';
import DbParameterGroupService from '../../../../services/DbParameterGroupService';
import CcxIconCheckCircleTwoTone from '../../../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../../../ccx/icons/CcxIconCloseCircleTwoTone';
import DbParametersModal from '../../../dbParameters/DbParametersModal';
import {
    DbParameter,
    DbParameterGroup,
} from '../../../../types/DbParameterGroup';
import useDataStoreJobs from '../../../../core/hooks/useDataStoreJobs';
import {
    isSyncningParameters,
    wrapDbParameterValues,
} from '../../../../core/helpers';
import { mapDbParameterGroupsToOptions } from '../../../../utils/dbParameterGroups';
import { RootState } from '../../../../redux/store';

interface Props {
    isEditMode: boolean;
    onChange: (name: any, value: string | boolean) => void;
    dbParameters: DbParameters | undefined;
    loading: boolean;
}

const PER_PAGE = 100;
const PAGE = 1;

function DatastoreSettingDbParameters({
    isEditMode,
    onChange,
    loading,
    dbParameters,
}: Props): ReactElement {
    const { Option } = Select;
    const [editableFields, setEditableFields] = useState<any[]>([]);
    const { dataStore } = useAppSelector((state: RootState) => state.dataStore);
    const [form] = Form.useForm();
    const [selectedGroup, setSelectedGroup] = useState<any>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(loading);
    const [isAssignGroupModalVisible, setIsAssignGroupModalVisible] =
        useState<boolean>(false);
    const [isDbParametersModalVisible, setIsDbParametersModalVisible] =
        useState<boolean>(false);
    const [isDbParameterEditable, setIsDbParameterEditable] =
        useState<boolean>(false);
    const [dbParametersTableData, setDbParametersTableData] = useState<
        DbParameter[]
    >([]);
    const [record, setRecord] = useState<any>();
    const { jobs, refresh: refreshJobs } = useDataStoreJobs({
        dataStoreUuid: dataStore?.dataStoreUuid,
    });
    const [isSyncing, setIsSyncing] = useState<boolean>(false);
    const [dbParameterForm] = Form.useForm();
    const [dbParametersList, setDbParametersList] = useState<
        DbParameterGroup[]
    >([]);

    useEffect(() => {
        setIsSyncing(isSyncningParameters(jobs));
    }, [jobs]);

    const getFormattedValues = (value: any, isDefault: boolean) => {
        const formattedValue = value
            .split(',')
            .reduce((result: any, item: any, index: number) => {
                const commaCount = result.split(',').length - 1;
                if (result === '' && isDefault) {
                    return `Default: ${item}`;
                } else if (result === '' && !isDefault) {
                    return `${item}`;
                } else if (commaCount % 2 === 1) {
                    return `${result},\n${item}`;
                } else {
                    return `${result},${item}`;
                }
            }, '');

        return formattedValue;
    };

    useEffect(() => {
        if (!isEditMode) {
            setEditableFields([]);
        }
    }, [isEditMode]);

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        if (dataStore && isDbParameterEditable) {
            editableParameter();
        }
    }, [isDbParameterEditable, dataStore]);

    const editableParameter = async () => {
        if (!dataStore || !selectedGroup) return;
        const defaultDbParameters =
            await DbParameterGroupService.getDefaultDbParameterGroup(
                dataStore.getDatabaseVendor()
            );
        setRecord({
            databaseVendor: selectedGroup.databaseVendor,
            databaseVersion: selectedGroup.databaseVersion,
            databaseType: selectedGroup.databaseType,
            dbParameters: wrapDbParameterValues(
                selectedGroup.dbParameters,
                defaultDbParameters.parameters
            ),
            description: selectedGroup.description,
            dataStores: selectedGroup.dataStores,
            name: selectedGroup.name,
        });
        setIsDbParametersModalVisible(true);
    };

    useEffect(() => {
        if (isDbParametersModalVisible && dataStore && !selectedGroup) {
            const defaultDbParameters = getDefaultDbParameters();
            if (!defaultDbParameters) return;
        }
    }, [isDbParametersModalVisible]);

    const getDefaultDbParameters = async () => {
        if (!dataStore) return;
        const defaultDbParameters =
            await DbParameterGroupService.getDefaultDbParameterGroup(
                dataStore?.getDatabaseVendor()
            );

        const formattedRecord = {
            databaseVendor: dataStore.getDatabaseVendor(),
            databaseVersion: dataStore.getDatabaseVersion(),
            databaseType: dataStore.getClusterType(),
            dbParameters: wrapDbParameterValues(
                dataStore.parameterGroupSync.parameters,
                defaultDbParameters?.parameters
            ),
        };
        setRecord(formattedRecord);
    };

    useEffect(() => {
        if (dataStore && dataStore.parameterGroupId) {
            getAttachedDbParameterGroup(dataStore.getParameterGroupId());
        }
    }, [dataStore]);

    const getAttachedDbParameterGroup = async (groupId: string) => {
        try {
            const attachedGroup =
                await DbParameterGroupService.getSingleDbParameterGroup(
                    groupId
                );
            if (attachedGroup) {
                setSelectedGroup(attachedGroup);
            }
        } catch (error) {
            showNotifications(false, 'Error', 'Failed to get details');
        }
    };

    const getMinMaxDefaultValues = (record: any) => {
        return (
            <div className={styles.DatastoreSettingDbParametersDefault}>
                {record?.type === Type.NUMBER && (
                    <>
                        <span>{`Max: ${record?.max} | `}</span>
                        <span>{`Min: ${record?.min} | `}</span>
                    </>
                )}
                {record?.defaultValue && (
                    <span>
                        {getFormattedValues(record?.defaultValue, true)}
                    </span>
                )}
            </div>
        );
    };

    function renderInputField(record: any) {
        switch (record?.type) {
            case Type.BOOLEAN:
                return (
                    <Checkbox
                        defaultChecked={
                            record?.value
                                ? JSON.parse(record?.value)
                                : JSON.parse(record?.defaultValue)
                        }
                        onChange={(e: {
                            target: { checked: string | boolean };
                        }) => onChange(record?.name, e.target.checked)}
                    />
                );
            case Type.SELECT:
                return (
                    <Select
                        defaultValue={
                            record?.value ? record?.value : record?.defaultValue
                        }
                        className={styles.DatastoreSettingDbParametersSelect}
                        onChange={(value: string | boolean) =>
                            onChange(record?.name, value)
                        }
                        onKeyDown={(e: {
                            key: string;
                            preventDefault: () => any;
                        }) => {
                            e.key === 'Enter' && e.preventDefault();
                        }}
                    >
                        {record?.options?.map((item: any, index: number) => {
                            return (
                                <Option value={item} key={`${index}`}>
                                    {item}
                                </Option>
                            );
                        })}
                    </Select>
                );
            case Type.NUMBER:
                return (
                    <Form.Item
                        name={record?.name}
                        className={styles.DatastoreSettingDbParametersField}
                    >
                        <Input
                            type="number"
                            autoComplete="off"
                            min={record?.min}
                            max={record?.max}
                            defaultValue={
                                record?.value
                                    ? record?.value
                                    : record?.defaultValue
                            }
                            onKeyDown={(e: {
                                key: string;
                                preventDefault: () => any;
                            }) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                            onChange={(e: {
                                target: { value: string | boolean };
                            }) => onChange(record?.name, e.target.value)}
                        />
                    </Form.Item>
                );
            case Type.TEXT:
                return (
                    <Form.Item
                        name={record?.name}
                        className={styles.DatastoreSettingDbParametersField}
                    >
                        <Input.TextArea
                            autoComplete="off"
                            defaultValue={
                                record?.value
                                    ? record?.value
                                    : record?.defaultValue
                            }
                            onKeyDown={(e: {
                                key: string;
                                preventDefault: () => any;
                            }) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                            onChange={(e: {
                                target: { value: string | boolean };
                            }) => onChange(record?.name, e.target.value)}
                        />
                    </Form.Item>
                );

            default:
                return null;
        }
    }

    const toggleEditableField = (fieldName: any) => {
        if (editableFields.includes(fieldName)) {
            setEditableFields(
                editableFields.filter((field: any) => field !== fieldName)
            );
        } else {
            setEditableFields([...editableFields, fieldName]);
        }
    };

    const databasesColumns = [
        {
            title: 'Parameter Name',
            key: 'name',
            width: 450,
            render: (text: string, record: any) => {
                return (
                    record && (
                        <div>
                            <span>
                                <span
                                    className={
                                        styles.DatastoreSettingDbParametersName
                                    }
                                >
                                    {record?.name}
                                </span>
                                <InfoIcon
                                    info={<span>{record.description}</span>}
                                />
                            </span>
                            <br />
                            {getMinMaxDefaultValues(record)}
                        </div>
                    )
                );
            },
        },
        {
            title: 'Current Value',
            width: 650,
            key: 'value',
            render: (text: string, record: any) => {
                if (editableFields.includes(record?.name) && isEditMode) {
                    return renderInputField(record);
                } else {
                    return (
                        <div
                            className={styles.DatastoreSettingDbParametersEdit}
                        >
                            <span>
                                {getFormattedValues(
                                    record?.value || record?.defaultValue,
                                    false
                                )}
                            </span>
                            {!editableFields.includes(record?.name) &&
                                isEditMode && (
                                    <span
                                        className={
                                            styles.DatastoreSettingDbParametersEditIcon
                                        }
                                    >
                                        <Button
                                            type="text"
                                            icon={<EditOutlined />}
                                            onClick={() =>
                                                toggleEditableField(record.name)
                                            }
                                        />
                                    </span>
                                )}
                        </div>
                    );
                }
            },
        },
    ];

    const checkIsLegacyDataStore = async () => {
        if (dataStore?.dataStoreUuid === undefined) {
            return false;
        }
        try {
            setIsLoading(true);
            const isLegacy =
                await DbParameterGroupService.verifyLegacyDatastore(
                    dataStore?.dataStoreUuid
                );
            if (isLegacy.hasLegacyParameters()) {
                createLegacyGroup();
            } else {
                setIsDbParametersModalVisible(true);
            }
            setIsLoading(false);
        } catch (error) {
            showNotifications(
                false,
                'Error',
                'Failed to verify legacy datastore'
            );
            setIsLoading(false);
            return false;
        }
        return true;
    };

    const assignParameterGroup = async (
        groupSelected: any,
        dataStoreUUID: string
    ) => {
        try {
            await DbParameterGroupService.applyDbParameterGroup(
                groupSelected.uuid,
                dataStoreUUID
            );
            showNotifications(
                true,
                'Success',
                'Parameter group assigned successfully'
            );
            setIsAssignGroupModalVisible(false);
            setSelectedGroup(groupSelected);
            refreshJobs();
        } catch (error) {
            setSelectedGroup(groupSelected);
            showNotifications(
                false,
                'Error',
                'Failed to assign parameter group'
            );
            setIsAssignGroupModalVisible(false);
        }
    };

    const syncGroup = async (groupId: string) => {
        setIsLoading(true);
        try {
            await DbParameterGroupService.syncDbParameterGroup(
                groupId,
                true,
                selectedGroup?.dbParameters
            );
            showNotifications(
                true,
                'Success',
                'Parameter group syncing job started'
            );
            refreshJobs();
        } catch (error) {
            showNotifications(false, 'Error', 'Failed to sync parameter group');
        } finally {
            setIsLoading(false);
        }
    };

    const createLegacyGroup = async () => {
        if (!dataStore?.dataStoreUuid) {
            return;
        }
        await DbParameterGroupService.createLegacyDbParameterGroup(
            dataStore.dataStoreUuid
        );
        setSelectedGroup({
            uuid: dataStore.getUUID(),
            name: dataStore.getUUID(),
            database_vendor: dataStore.getDatabaseVendor(),
            database_version: dataStore.getDatabaseVersion(),
            database_type: dataStore.getClusterType(),
        });
    };

    const handleCancel = () => {
        dbParameterForm.resetFields();
        setIsDbParametersModalVisible(false);
    };

    const updateDbParametersWithGroupValues = (
        dbParameters: DbParameter[]
    ): DbParameter[] => {
        const { parameters, status } = dataStore?.parameterGroupSync || {};
        if (!dbParameters || !parameters) return [];

        if (!status) {
            return dbParameters.map((param) => {
                return {
                    ...param,
                    value: parameters[param.name],
                };
            });
        } else {
            return [];
        }
    };

    useEffect(() => {
        const parameters = dbParameters?.parameters;
        const groupDbParams = selectedGroup?.dbParameters;

        if (parameters && (groupDbParams || dataStore)) {
            setDbParametersTableData(
                updateDbParametersWithGroupValues(parameters)
            );
        }
    }, [dbParameters?.parameters, selectedGroup?.dbParameters, dataStore]);

    const onSubmit = async (formData: any) => {
        const data = {
            name: formData.name,
            database_vendor: formData.databaseVendor,
            database_version: formData.databaseVersion,
            database_type: formData.configuration,
            description: formData.description,
            parameters: formData.dbParameters,
            data_stores: [dataStore?.dataStoreUuid],
        };

        try {
            await DbParameterGroupService.createDbParameterGroup(data);
            showNotifications(
                true,
                'Success',
                'Parameter group created successfully'
            );

            setIsDbParametersModalVisible(false);
        } catch (error) {
            showNotifications(
                false,
                'Error',
                'Failed to create parameter group'
            );
            setIsDbParametersModalVisible(false);
        }
    };

    const updateDbParameters = async (formData: any) => {
        const data = {
            name: formData.name,
            database_vendor: formData.databaseVendor,
            database_version: formData.databaseVersion,
            database_type: formData.configuration,
            description: formData.description,
            parameters: formData.dbParameters,
            data_stores: [dataStore?.dataStoreUuid],
        };

        try {
            await DbParameterGroupService.updateDbParameterGroup(
                selectedGroup?.uuid,
                data
            );
            showNotifications(
                true,
                'Success',
                'Parameter group updated successfully'
            );
            refreshJobs();
        } catch (error) {
            showNotifications(
                false,
                'Error',
                'Failed to update parameter group'
            );
        } finally {
            setIsLoading(false);
            setIsDbParametersModalVisible(false);
            setIsDbParameterEditable(false);
        }
    };

    const showNotifications = (
        type: boolean,
        message: string,
        description: string
    ) => {
        notification.open({
            message: message,
            description: description,
            icon: type ? (
                <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />
            ) : (
                <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
            ),
            duration: 4.5,
        });
    };

    useEffect(() => {
        fetchDbParameters();
    }, []);

    const fetchDbParameters = async () => {
        try {
            setIsLoading(true);
            const fetchDbParameters =
                await DbParameterGroupService.getDbParameterGroups(
                    PER_PAGE,
                    PAGE
                );
            setDbParametersList(fetchDbParameters.parameterGroups);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const mapDbParametersGroup = () => {
        if (!dataStore || !dbParametersList) return [];
        return mapDbParameterGroupsToOptions(
            dbParametersList,
            dataStore.getDatabaseVendor(),
            dataStore.getDatabaseVersion(),
            dataStore?.parameterGroupId
        );
    };

    return isLoading ? (
        <AppLoading />
    ) : (
        <>
            <ParameterGroupDisplay
                selectedGroup={selectedGroup}
                isSyncing={isSyncing}
                setVisible={setIsAssignGroupModalVisible}
                syncGroup={syncGroup}
                dataStore={dataStore}
                loading={isLoading}
                createLegacyGroup={checkIsLegacyDataStore}
                mapDbParametersGroup={mapDbParametersGroup}
                setIsDbParameterEditable={setIsDbParameterEditable}
            />
            <AppTable
                columns={databasesColumns}
                data={dbParametersTableData}
                rowKey="uniqueKey"
                pagination={{
                    hideOnSinglePage: true,
                }}
                expandable={false}
            />
            <AssignGroupModal
                form={form}
                visible={isAssignGroupModalVisible}
                dataStore={dataStore}
                setVisible={setIsAssignGroupModalVisible}
                onSubmit={assignParameterGroup}
                dbParametersList={dbParametersList}
                mapDbParametersGroup={mapDbParametersGroup}
            />
            <DbParametersModal
                setVisible={setIsDbParametersModalVisible}
                visible={isDbParametersModalVisible}
                handleCancel={handleCancel}
                form={dbParameterForm}
                operation={isDbParameterEditable ? 'edit' : 'create'}
                onSubmit={isDbParameterEditable ? updateDbParameters : onSubmit}
                record={record}
                disableFields={true}
            />
        </>
    );
}

export default DatastoreSettingDbParameters;
