export interface LegacyDatastoreInterface {
    isLegacyDatastore: boolean;
}

export type LegacyDatastoreProps = {
    has_legacy_parameters: boolean;
};

export default class LegacyDatastore implements LegacyDatastoreInterface {
    readonly isLegacyDatastore: boolean;

    constructor(props: LegacyDatastoreProps) {
        this.isLegacyDatastore = props.has_legacy_parameters;
    }

    hasLegacyParameters(): boolean {
        return this.isLegacyDatastore;
    }
}
