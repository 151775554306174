import React from 'react';
import moment from 'moment';
import { Col, DatePicker, Row } from 'antd';
import { HistoryOutlined } from '@severalnines/frontend_hub/libs/icons';
import styles from './DatabaseGrowthHeading.module.less';
import { DateRangeProps } from './DatabaseGrowth';

interface DatabaseGrowthHeadingProps {
    dateFormat: string;
    disabledDate: Function;
    onChangeDateRange: Function;
    dateRange: DateRangeProps;
}

function DatabaseGrowthHeading({
    dateFormat,
    disabledDate,
    onChangeDateRange,
    dateRange,
}: DatabaseGrowthHeadingProps) {
    return (
        <Row justify="space-between" align="middle">
            <Col>
                <h4 className={styles.DbGrowthChartHeading}>Database Growth</h4>
                <div className={styles.ChartTip}>
                    Growth stats are updated every hour
                </div>
            </Col>
            <Col>
                <div className={styles.DatePickerContainer}>
                    <HistoryOutlined className={styles.HistoryOutlinedIcon} />
                    From
                    <DatePicker
                        className={styles.DatePicker}
                        format={dateFormat}
                        clearIcon={false}
                        suffixIcon={false}
                        onChange={(date) =>
                            onChangeDateRange({
                                ...dateRange.Range,
                                fromDate: moment(date).format(dateFormat),
                            })
                        }
                        disabledDate={(current) => disabledDate(current)}
                        value={moment(
                            dateRange?.Range.fromDate,
                            dateFormat
                        ).startOf('day')}
                    />
                    To
                    <DatePicker
                        className={styles.DatePicker}
                        clearIcon={false}
                        suffixIcon={false}
                        format={dateFormat}
                        onChange={(date) =>
                            onChangeDateRange({
                                ...dateRange.Range,
                                toDate: moment(date).format('YYYY-MM-DD'),
                            })
                        }
                        disabledDate={(current) => disabledDate(current)}
                        value={moment(
                            dateRange?.Range.toDate,
                            dateFormat
                        ).endOf('day')}
                    />
                </div>
            </Col>
        </Row>
    );
}

export default DatabaseGrowthHeading;
