import React, { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './AddServiceCreateVPCStep.module.less';
import WizardFormConfiguration from '@severalnines/bar-frontend-components/build/lib/Navigation/Wizard/WizardFormConfiguration';
import { Divider } from 'antd';
import { Form } from 'antd';
import { message } from 'antd';
import { WizardFormConfigurationStep2 } from './WizardFormConfigurationStep2';
import useContentProviders from '../../../core/hooks/useContentProviders';
import useContentCidrs from '../../../core/hooks/useContentCidrs';
import { Input } from 'antd';
import { Select } from 'antd';
import CloudProviderRegion from '../../../types/CloudProviderRegion';
import { Row } from 'antd';
import { Col } from 'antd';
import AppFlagIcon from '../../ccx/common/AppFlagIcon';
import { WizardFormConfigurationStep6 } from './WizardFormConfigurationStep6';
import Coupon from '../../../types/Coupon';
import Card from '../../../types/Card';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import CcxIconCloseCircleTwoTone from '../../ccx/icons/CcxIconCloseCircleTwoTone';
import CloudProvider from '../../../types/CloudProvider';
import { FormInstance } from 'antd/lib/form';
import SetupIntent from '../../../types/SetupIntent';
import { useAppSelector } from '../../../redux/hooks';

interface Props extends CcxComponentProps {
    form: FormInstance;
    vpcContext: any;
    doApiRequest: Function;
    validateForm: Function;
    providerLogo: Function;
    createUserSubscription: Function;
    subscriptionUser: any;
    handleCreateUser: Function;
    handleSetupIntent: Function;
    intentResponse?: SetupIntent;
}

export default function AddServiceCreateVPCStep({
    testId = 'AddServiceCreateVPCStep',
    form,
    vpcContext,
    doApiRequest,
    validateForm,
    providerLogo,
    createUserSubscription,
    subscriptionUser,
    handleCreateUser,
    handleSetupIntent,
    intentResponse,
}: Props): ReactElement {
    const { cloudProviders, loading: loadingProviders } = useContentProviders();
    const { cidrs, loading: loadingCidrs } = useContentCidrs();

    const [regions, setRegions] = useState<CloudProviderRegion[]>();
    const [region, setRegion] = useState<CloudProviderRegion>();

    const [checkedCoupon, setCheckedCoupon] = useState<Coupon>();
    const [selectedCard, setSelectedCard] = useState<Card>();
    const [billingForm] = Form.useForm();
    const [cardToken, setCardToken] = useState<any>();

    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const history = useHistory();

    useEffect(() => {
        if (cardToken) {
            handleSetupIntent(cardToken.id);
        }
    }, [cardToken]);

    const handleCancel = () => {
        history.goBack();
    };

    const { subscription } = useAppSelector((state) => state.user);

    const onSubmitButtonClick = async () => {
        const stepValid = await validateForm(form);

        if (stepValid) {
            try {
                form.getFieldValue('cloudProvider') &&
                    form.getFieldValue('region') &&
                    (await doApiRequest(
                        {
                            vpcName: form.getFieldValue('vpcName'),
                            cidrIpv4Block: form.getFieldValue('cidrIpv4Block'),
                        },
                        form.getFieldValue('cloudProvider'),
                        form.getFieldValue('region')
                    ));
            } catch (e) {
                console.log('error e=', e);
                message.error('There was an error.' + e);
            }
        }
    };

    const handleSubmit = async () => {
        try {
            setSubmitDisabled(true);

            if (!subscription?.valid) {
                if (subscriptionUser && intentResponse) {
                    await createUserSubscription(
                        billingForm,
                        intentResponse.paymentMethodID
                    );
                } else {
                    message.error(
                        'Please complete "Billing address" and "Card information" sections'
                    );
                    return;
                }
            }

            await onSubmitButtonClick();

            form.resetFields();

            vpcContext.refresh && (await vpcContext.refresh());

            history.push({
                pathname: '/',
            });
        } catch (e: any) {
            notification.open({
                message: 'Create VPC',
                description: `There was an error creating your VPC. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
        } finally {
            setSubmitDisabled(false);
        }
    };

    const steps = [
        <WizardFormConfiguration.Step
            key="0"
            validate={['vpcName', 'cidrIpv4Block']}
            title="Configuration"
            hasRequiredFields={true}
        >
            <div className={styles.AddServiceCreateVPCConfiguration}>
                <Form.Item
                    name="vpcName"
                    label="Name"
                    rules={[
                        {
                            required: true,
                            message: 'New VPC requires a name',
                        },
                    ]}
                >
                    <Input
                        autoComplete="off"
                        data-testid={`${testId}VPCNameInput`}
                        placeholder="Name your VPC"
                    />
                </Form.Item>

                {cidrs && (
                    <Form.Item
                        name="cidrIpv4Block"
                        label="IPv4 CIDR block"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a CIDR block',
                            },
                        ]}
                    >
                        <Select
                            data-testid={`${testId}CIDRSelect`}
                            placeholder="Select a CIDR block from the list"
                        >
                            {cidrs?.map((c: string) => {
                                return (
                                    <Select.Option value={c} key={c}>
                                        {c}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                )}
            </div>
        </WizardFormConfiguration.Step>,
        <WizardFormConfiguration.Step
            key="1"
            validate={['cloudProvider', 'region']}
            title="Cloud service"
            hasRequiredFields={true}
        >
            <WizardFormConfigurationStep2
                cloudProviders={cloudProviders?.filter((c: CloudProvider) => {
                    // only show AWS for now
                    return c.isAws();
                })}
                regions={regions}
                handleCloudProviderChange={() =>
                    setRegions(form.getFieldValue('cloudProvider').regions)
                }
                handleRegionChange={(e: any) => {
                    setRegion(e.target.value);
                }}
                form={form}
            />
        </WizardFormConfiguration.Step>,
        <WizardFormConfiguration.Step
            key="2"
            title="Preview"
            hasRequiredFields={true}
            nextButtonDisabled={submitDisabled}
        >
            <div>
                <p>
                    <strong>Configuration</strong>
                </p>

                <Row className={styles.AddServiceCreateVPCStepRow}>
                    <Col
                        span={24}
                        className={styles.AddServiceCreateVPCStepCol}
                    >
                        <strong>Name: </strong>{' '}
                        {` ${form.getFieldValue('vpcName')}`}
                    </Col>
                    <Col
                        span={24}
                        className={styles.AddServiceCreateVPCStepCol}
                    >
                        <strong>IPv4 CIDR block: </strong>{' '}
                        {` ${form.getFieldValue('cidrIpv4Block')}`}
                    </Col>
                </Row>
                <Divider />

                <p>
                    <strong>Cloud service</strong>
                </p>

                <Row className={styles.AddServiceCreateVPCStepRow}>
                    {form.getFieldValue('cloudProvider') && (
                        <Col
                            span={24}
                            className={styles.AddServiceCreateVPCStepCol}
                        >
                            <strong>Cloud service provider:</strong>{' '}
                            {providerLogo(form.getFieldValue('cloudProvider'))}
                        </Col>
                    )}

                    {form.getFieldValue('region') && (
                        <Col
                            span={24}
                            className={styles.AddServiceCreateVPCStepCol}
                        >
                            <strong>Region:</strong>{' '}
                            <AppFlagIcon
                                code={form.getFieldValue('region').countryCode}
                            />{' '}
                            {form.getFieldValue('region').displayCode} (
                            {form.getFieldValue('region').city})
                        </Col>
                    )}
                </Row>
            </div>
        </WizardFormConfiguration.Step>,
        <WizardFormConfiguration.Step
            key="6"
            title="Billing"
            disabled={true}
            nextButtonDisabled={
                !(subscriptionUser && intentResponse) || submitDisabled
            }
        >
            <WizardFormConfigurationStep6
                setCheckedCoupon={setCheckedCoupon}
                checkedCoupon={checkedCoupon}
                setSelectedCard={setSelectedCard}
                selectedCard={selectedCard}
                subscription={subscription}
                billingForm={billingForm}
                setCardToken={setCardToken}
                cardToken={cardToken}
                handleCreateUser={handleCreateUser}
                subscriptionUser={subscriptionUser}
                isCardAttached={!!intentResponse}
            />
        </WizardFormConfiguration.Step>,
    ];

    if (subscription?.valid) {
        steps.pop();
    }

    return (
        <div
            className={styles.AddServiceCreateVPCStepContainer}
            data-testid={testId}
        >
            <h4 data-testid={`${testId}title`}>
                Create a virtual private cloud
            </h4>
            <Divider className={styles.AddServiceCreateVPCDivider} />
            <WizardFormConfiguration
                form={form}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
                defaultActiveStep="0"
                autoFocusFirstInput={false}
                steps={steps}
            />
        </div>
    );
}
