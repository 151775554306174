import { ReactElement, useState } from 'react';
import CcxComponentProps from '../../core/CcxComponent';
import { Button, Form, notification } from 'antd';
import DbParametersModal from './DbParametersModal';
import DbParameterGroupService from '../../services/DbParameterGroupService';
import CcxIconCheckCircleTwoTone from '../ccx/icons/CcxIconCheckCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';

interface Props extends CcxComponentProps {
    buttonType?:
        | 'primary'
        | 'link'
        | 'text'
        | 'ghost'
        | 'default'
        | 'dashed'
        | undefined;
    buttonText?: string;
    className?: string;
    refresh?: () => void;
}

export type UrlProps = {
    projectUuid: string;
};

function CreateDbParameters({
    buttonType = 'primary',
    buttonText = 'Create Group',
    testId = 'CreateDbParameters',
    refresh,
}: Props): ReactElement {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [form] = Form.useForm();
    const handleClick = () => {
        setOpenModal(true);
    };

    const handleCancel = () => {
        setOpenModal(false);
    };

    const onSubmit = async (formData: any) => {
        setOpenModal(false);
        const data = {
            name: form.getFieldValue('groupName'),
            database_vendor: form.getFieldValue('vendor'),
            database_version: form.getFieldValue('version'),
            database_type: form.getFieldValue('configuration'),
            description: form.getFieldValue('description'),
            parameters: formData.dbParameters,
        };

        try {
            await DbParameterGroupService.createDbParameterGroup(data);
            notification.open({
                message: 'DB Parameter Group Created',
                description:
                    'Database parameter group has been created successfully.',
                icon: <CcxIconCheckCircleTwoTone twoToneColor="#52c41a" />,
            });
            if (refresh) {
                refresh();
            }
        } catch (error) {
            notification.open({
                message: 'Failed to Create DB Parameter Group',
                description: `An error occurred while creating the database parameter group: ${error}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
            console.error(error);
        }
    };

    return (
        <>
            <Button
                onClick={handleClick}
                type={buttonType}
                data-testid={testId}
            >
                {buttonText}
            </Button>
            <DbParametersModal
                visible={openModal}
                onSubmit={onSubmit}
                setVisible={setOpenModal}
                handleCancel={handleCancel}
                operation={'create'}
                form={form}
                disableFields={false}
            />
        </>
    );
}

export default CreateDbParameters;
