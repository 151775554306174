export interface DbParametersProps {
    error: string;
    parameters?: {
        [key: string]: string;
    };
    sync: boolean;
}

interface DbParameterSyncInterface {
    status: string;
    parameters: {
        [key: string]: string;
    };
    sync: boolean;
}

export class DbParameterSync implements DbParameterSyncInterface {
    readonly status: string;
    readonly parameters: {
        [key: string]: string;
    };
    readonly sync: boolean;

    constructor(props: DbParametersProps) {
        this.status = props.error;
        this.parameters = props.parameters || {};
        this.sync = props.sync;
    }
}
