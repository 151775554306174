import { DrawerProps } from 'antd';
import React from 'react';
import { useForm } from 'antd/lib/form/Form';
import BackupsFormModal from './BackupsFormModal';
import DeploymentsItem from '../../types/DeploymentsItem';
import BackupsSettingsForm from './BackupsSettingsForm';
import { Draft } from 'immer';

type BackupSettingsModalProps = DrawerProps & {
    deployment: Draft<DeploymentsItem>;
    onSuccess?: () => void;
};
export default function BackupSettingsModal({
    deployment,
    onSuccess,
    ...rest
}: BackupSettingsModalProps) {
    const [form] = useForm();
    return (
        <BackupsFormModal form={form} title="Backup Settings" {...rest}>
            <BackupsSettingsForm
                form={form}
                deployment={deployment}
                onSuccess={onSuccess}
            />
        </BackupsFormModal>
    );
}
