import React from 'react';
import { MessageOutlined } from '@severalnines/frontend_hub/libs/icons';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

const CcxIconMessageOutlined = ({
    testId = 'CcxIconMessageOutlined',
}: Props) => {
    return <MessageOutlined data-testid={testId} />;
};

export default CcxIconMessageOutlined;
