import { Col, Row, Card } from 'antd';
import { useEffect, useState } from 'react';
import CcxComponentProps from '../../core/CcxComponent';
import styles from './PromoCodeCard.module.less';
import { notification } from 'antd';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import { Modal } from 'antd';
import BillingService from '../../services/BillingService';
import CcxIconCheckCircle from '../ccx/icons/CcxIconCheckCircle';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import AppConfirmDialog from '../AppConfirmDialog';
import {
    DeleteOutlined,
    InfoCircleOutlined,
} from '@severalnines/frontend_hub/libs/icons';
import Coupon from '../../types/Coupon';
import { Tooltip } from 'antd';

interface Props extends CcxComponentProps {
    coupon: Coupon;
    refresh: Function;
}

export function PromoCodeCard({
    testId = 'PromoCodeCard',
    coupon,
    refresh,
}: Props) {
    const [couponObject, setCouponObject] = useState<any>();
    const [showIcon, setShowIcon] = useState<boolean>(false);

    useEffect(() => {
        if (coupon) {
            const onConfirmDelete = () => {
                return new Promise(async (resolve, reject) => {
                    notification.open({
                        message: 'Remove Promo code',
                        description: 'Your Promo code will be removed soon.',
                        icon: <CcxIconInfoCircleTwoTone />,
                    });

                    Modal.destroyAll();

                    try {
                        await BillingService.deleteCoupon();

                        refresh && (await refresh());

                        notification.open({
                            message: 'Remove Promo code',
                            description: 'Your Promo code was removed.',
                            icon: <CcxIconCheckCircle />,
                        });

                        resolve(null);
                    } catch (e) {
                        notification.open({
                            message: 'Remove Promo code',
                            description: `There was an error removing your Promo code. ${e}`,
                            icon: (
                                <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                            ),
                        });

                        console.error(e);

                        reject();
                    }
                }).catch(() => console.log('Oops errors!'));
            };

            const action = (
                <AppConfirmDialog
                    critical={true}
                    onOk={onConfirmDelete}
                    actionIcon={
                        <DeleteOutlined
                            data-testid={'DeleteOutlined'}
                            style={{ color: 'black' }}
                        />
                    }
                    content="The Promo code will be deleted."
                />
            );

            setCouponObject({
                ...coupon,
                action,
            });
        }
    }, [coupon]);

    return couponObject ? (
        <Card
            size="small"
            className={styles.PromoCodeCard}
            data-testid={`${testId}`}
            onMouseEnter={() => {
                setShowIcon(true);
            }}
            onMouseLeave={() => {
                setShowIcon(false);
            }}
        >
            <Row justify="space-between">
                <Col className={styles.PromoCodeCardDetails}>
                    <span>{couponObject.name}</span>

                    <Tooltip
                        placement="bottom"
                        title={`Code: ${couponObject.coupon}`}
                    >
                        <InfoCircleOutlined
                            data-testid={'InfoCircleOutlined'}
                        />
                    </Tooltip>
                </Col>

                <Col span={1}>{showIcon && couponObject.action}</Col>
            </Row>
        </Card>
    ) : null;
}
