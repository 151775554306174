import {
    API_DB_CONFIGURATION,
    APPLY,
    DB_PARAMETERS_GROUP,
    LEGACY,
} from '../core/CcxEnv';
import { DBParameterDefaults } from '../types/DBparameterDefaults';
import { DbParameterGroup } from '../types/DbParameterGroup';
import { DbParametersGroup } from '../types/DbParametersGroup';
import LegacyDatastore from '../types/LegacyDatastore';
import ApiService from './ApiService';

// constants
const API_URL = API_DB_CONFIGURATION;

export default class DbParameterGroupService extends ApiService {
    /**
     * Get list of DB parameter groups.
     */
    static async getDbParameterGroups(
        perPage: number = 10,
        page: number = 1
    ): Promise<DbParametersGroup> {
        const response = await this.request(
            'GET',
            `${DB_PARAMETERS_GROUP}?page_size=${perPage}&page=${page}`
        );
        return new DbParametersGroup(response);
    }

    static async getDefaultDbParameterGroup(
        db_vendor: string
    ): Promise<DBParameterDefaults> {
        const response = await this.request(
            'GET',
            `${DB_PARAMETERS_GROUP}/defaults?db_vendor=${db_vendor}`
        );
        return new DBParameterDefaults(response);
    }

    /**
     * Create a new DB parameter group.
     */
    static async createDbParameterGroup(requestData: any) {
        const reqData = { data: requestData };
        const response = await this.request(
            'POST',
            `${DB_PARAMETERS_GROUP}`,
            reqData
        );
        return response;
    }

    /**
     * Update an existing DB parameter group.
     * @param groupId - The ID of the group to update.
     */
    static async updateDbParameterGroup(groupId: string, parameters: any) {
        await this.request(
            'PUT',
            `${DB_PARAMETERS_GROUP}/${groupId}?sync=true`,
            {
                data: {
                    ...parameters,
                },
            }
        );
    }

    static async getSingleDbParameterGroup(
        groupId: string
    ): Promise<DbParameterGroup> {
        const response = await this.request(
            'GET',
            `${DB_PARAMETERS_GROUP}/${groupId}`
        );
        return new DbParameterGroup(response);
    }

    static async applyDbParameterGroup(groupId: string, dataStoreUuid: string) {
        await this.request(
            'PUT',
            `${DB_PARAMETERS_GROUP}/${APPLY}/${groupId}/${dataStoreUuid}`
        );
    }

    static async verifyLegacyDatastore(
        dataStoreUuid: string
    ): Promise<LegacyDatastore> {
        const response = await this.request(
            'GET',
            `${DB_PARAMETERS_GROUP}/${LEGACY}/${dataStoreUuid}`
        );
        return new LegacyDatastore(response);
    }

    static async createLegacyDbParameterGroup(dataStoreUuid: string) {
        await this.request(
            'PUT',
            `${DB_PARAMETERS_GROUP}/${LEGACY}/${dataStoreUuid}`
        );
    }

    /**
     * Delete a DB parameter group.
     * @param groupId - The ID of the group to delete.
     */
    static async deleteDbParameterGroup(groupId: string) {
        await this.request('DELETE', `${DB_PARAMETERS_GROUP}/${groupId}`);
    }

    static async syncDbParameterGroup(
        groupId: string,
        sync: boolean,
        dbParameters: any
    ) {
        await this.request(
            'PUT',
            `${DB_PARAMETERS_GROUP}/${groupId}?sync=${sync}`,
            {
                data: {
                    parameters: { ...dbParameters },
                },
            }
        );
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
