import { DeleteOutlined } from '@severalnines/frontend_hub/libs/icons';
import React from 'react';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

const CcxIconDeleteOutlined = ({ testId = 'CcxIconDeleteOutlined' }: Props) => {
    return <DeleteOutlined data-testid={testId} />;
};

export default CcxIconDeleteOutlined;
