import React, { ReactElement, useEffect, useState } from 'react';
import { DatabaseOutlined } from '@severalnines/frontend_hub/libs/icons';
import ButtonModalForm from '../ButtonModalForm';
import DbUserService, { AddDatabaseProps } from '../../services/DbUserService';
import { notification } from 'antd';
import CcxIconCheckCircle from '../ccx/icons/CcxIconCheckCircle';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import CcxComponentProps from '../../core/CcxComponent';
import { getDatabaseNameValidators } from '../../core/validation/databaseValidation';
import { DatabaseType } from '../../types/Db';
import useDbUsers from '../../core/hooks/useDbUsers';
import DbUser from '../../types/DbUser';
import DeploymentsItem from '../../types/DeploymentsItem';

interface AddDbDatabaseProps extends CcxComponentProps {
    uuid: string;
    onSuccess: Function;
    type: DatabaseType;
    currentDeployment?: DeploymentsItem | null | undefined;
}

function AddDbDatabase({
    onSuccess,
    uuid,
    testId = 'AddDbDatabase',
    type,
    currentDeployment,
}: AddDbDatabaseProps): ReactElement {
    const { dbUsers } = useDbUsers(uuid);
    const [usersHosts, setUsersHosts] = useState<string[]>([]);

    useEffect(() => {
        if (dbUsers && dbUsers.accounts) {
            setUsersHosts(
                dbUsers.accounts?.map((u: DbUser) => {
                    return u.username;
                })
            );
        }
    }, [dbUsers]);

    const fields = [
        {
            name: ['dbName'],
            testId: 'AddDbDatabaseDbName',
            required: true,
            label: 'Database name',
            rules: getDatabaseNameValidators(type),
        },
        type === 'postgres' && {
            name: ['dbOwner'],
            testId: 'AddDbDatabaseDbOwner',
            required: true,
            label: 'Db owner',
            placeholder: 'Select a Db owner',
            type: 'select',
            options: usersHosts
                ? usersHosts?.map((d) => {
                      return {
                          label: d,
                          value: d,
                      };
                  })
                : [],
        },
    ];

    const doApiRequest = async ({ dbName, dbOwner }: AddDatabaseProps) => {
        try {
            await DbUserService.addDatabase({
                uuid,
                dbName,
                dbOwner,
            });

            notification.open({
                message: 'Add database',
                description: `New database successfully created!`,
                icon: <CcxIconCheckCircle />,
            });

            return true;
        } catch (e) {
            notification.open({
                message: 'Add database',
                description: `There was an error creating your database. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });

            console.error(e);

            throw e;
        }
    };

    return (
        <ButtonModalForm
            disabled={
                !currentDeployment?.isOperable() || usersHosts.length === 0
            }
            title="Create new database"
            buttonText="Create new database"
            buttonIcon={<DatabaseOutlined />}
            onSubmit={doApiRequest}
            onSuccess={onSuccess}
            fields={fields}
            testId={testId}
            submitText="Create"
            formLayout="vertical"
            useGrid={true}
        ></ButtonModalForm>
    );
}

export default AddDbDatabase;
