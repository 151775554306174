import { Modal, Spin, notification } from 'antd';
import AppConfirmDialog from '../AppConfirmDialog';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import DeploymentsItem from '../../types/DeploymentsItem';
import BackupService from '../../services/BackupService';
import useBackupRestores from '../../core/hooks/useBackupRestores';
import Restore from '../../types/Restore';
import styles from './BackupsListRestoreButton.module.less';
import { RedoOutlined } from '@severalnines/frontend_hub/libs/icons';
import useDataStoreJobs from '../../core/hooks/useDataStoreJobs';
import { Draft } from '@reduxjs/toolkit';

type BackupsListRestoreButtonProps = {
    record: any;
    currentRestore?: Restore;
    deployment: Draft<DeploymentsItem>;
    setRestoreObj: (obj: any) => void;
    setOpenRestore: (value: boolean) => void;
};

export default function BackupsListRestoreButton({
    record,
    deployment,
    setRestoreObj,
    currentRestore,
    setOpenRestore,
}: BackupsListRestoreButtonProps) {
    const { refresh: refreshRestores } = useBackupRestores(
        deployment.dataStoreUuid
    );

    const { refresh: refreshJobs } = useDataStoreJobs({
        dataStoreUuid: deployment?.dataStoreUuid,
    });

    const onConfirmRestore = () => {
        if (deployment?.isRedis()) {
            return new Promise(async (resolve, reject) => {
                try {
                    await BackupService.restoreBackup(
                        deployment.dataStoreUuid,
                        record?.backupId
                    );

                    notification.open({
                        message: 'Restore Backup',
                        description: 'The backup will be restored soon.',
                        icon: <CcxIconInfoCircleTwoTone />,
                    });

                    refreshRestores();

                    refreshJobs();

                    Modal.destroyAll();

                    resolve(null);
                } catch (e) {
                    notification.open({
                        message: 'Restore Backup',
                        description: `There was an error restoring the backup. ${e}`,
                        icon: (
                            <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                        ),
                    });

                    console.error(e);

                    reject();
                }
            }).catch(() => console.log('Oops errors!'));
        } else {
            setOpenRestore(true);
            setRestoreObj(record);
        }
    };

    return (
        <div className={styles.BackupsActionsColumn}>
            {currentRestore &&
            !(
                currentRestore?.isStatusCompleted() ||
                currentRestore?.isStatusError()
            ) ? (
                <Spin />
            ) : (
                <AppConfirmDialog
                    disabled={!deployment?.isOperable()}
                    onOk={onConfirmRestore}
                    actionIcon={
                        <div className={styles.BackupActionRestoreButton}>
                            <RedoOutlined /> Restore
                        </div>
                    }
                    title={<strong>Restore a backup</strong>}
                    content="You are going to restore your database using the selected backup."
                />
            )}
        </div>
    );
}
