import DataStoreStatus, {
    DATA_STORE_STATUS_DEPLOY_FAILED,
} from './DataStoreStatus';
import Db, {
    CLUSTER_TYPE_EPHEMERAL,
    DB_VENDOR_MARIADB,
    DB_VENDOR_MSSQL,
    DB_VENDOR_PERCONA,
    DB_VENDOR_POSTGRESQL_V2,
    DB_VENDOR_REDIS,
    DB_VENDOR_UNKNOWN,
    DatabaseType,
} from './Db';
import FriendlyDateTime from './FriendlyDateTime';
import Vpc, { VpcClassProps } from './Vpc';
import CloudProvider from './CloudProvider';
import DataStoreMaintenanceSettings, {
    DataStoreMaintenanceSettingsClassProps,
} from './DataStoreMaintenanceSettings';
import DbAccount from './DbAccount';
import Helpers from '../services/Helpers';
import DeploymentOptions from './DeploymentOptions';
import DbNode from './DbNode';
import LbNode from './LbNode';
import Service from './Service';
import DataStoreEmailNotificationsSettings from './DataStoreEmailNotifications';
import Job, { JobClassProps } from './Job';
import DataStoreSpec, { DataStoreSpecClassProps } from './DataStoreSpec';
import { DbParametersProps, DbParameterSync } from './DbParameterSync';

export type DeploymentsItemClassProps = {
    account_id: string;
    azs: string[] | null;
    cloud_provider: string;
    cluster_id: number;
    cluster_name: string;
    cluster_size: number;
    cluster_status: string;
    cluster_status_text: string;
    cluster_type: string;
    cluster_type_name: string;
    created_at: string;
    current_job: JobClassProps;
    database_endpoint: string;
    database_vendor: string;
    database_version: string;
    db_account: any;
    deploy_progress: number;
    disk_size: number;
    effective_disk_size: number;
    disk_type: string;
    high_availability: boolean;
    instance_size: string;
    iops: number | null;
    is_deploying: boolean;
    maintenance_settings: DataStoreMaintenanceSettingsClassProps;
    not_operable_reason: string;
    operable: boolean;
    preferred_region?: string;
    region: CloudRegion;
    seen_at: string | null;
    ssl_enabled: boolean;
    tags: string[];
    updated_at: string;
    uuid: string;
    vpc: VpcClassProps | null;
    notes?: string;
    cloudProviderFullName: string;
    replica_url?: string;
    primary_url?: string;
    can_upgrade_to?: string;
    license?: string;
    notifications: DataStoreEmailNotificationsSettings;
    spec: DataStoreSpecClassProps;
    is_ca_available: boolean;
    parameter_group_id: string;
    parameter_group_sync: DbParametersProps;
};

export type CloudRegion = {
    code: string;
    display_code: string;
    name: string;
    country_code: string;
    continent_code: string;
    city: string;
};

export interface DeploymentsItemInterface {
    accountId: string;
    availabilityZones?: string[];
    cloudProvider: CloudProvider;
    clusterId: number;
    dataStoreName: string;
    numberOfNodes: number;
    clusterStatus: DataStoreStatus;
    clusterType: string;
    clusterTypeName: string;
    createdAt: string;
    deployProgress: number;
    volumeSize: number;
    volumeType: string;
    highAvailability: boolean;
    volumeIops: number;
    instanceSize: string;
    maintenanceSettings: DataStoreMaintenanceSettings;
    spec: DataStoreSpec;
    notOperableReason: string;
    currentJob: Job;
    operable: boolean;
    cloudRegion: CloudRegion;
    preferredRegion?: string;
    seenAt: string;
    sslEnabled: boolean;
    tags: string[];
    updatedAt: string;
    dataStoreUuid: string;
    db: Db;
    lastSeen: string;
    lastSeenFull: string;
    createdAtFull: string;
    getVpcName: Function;
    getVpcCidr: Function;
    vpc?: Vpc;

    isAws: Function;
    isSafespring: Function;
    isCityCloud: Function;
    isGoogle: Function;
    getName: Function;
    getTruncatedName: Function;
    getNumberOfNodes: Function;
    getDataStoreStatus: Function;
    getDataStoreStatusText: Function;
    getVolumeIops: Function;
    getDeploymentProgressText: Function;
    getMaintenanceDayOfWeek: Function;
    getMaintenanceEndHour: Function;
    getMaintenanceStartHour: Function;
    isProxySql: Function;
    isRedis(): boolean;
    getCloudProvider(): CloudProvider;
    getDbAccount(): DbAccount;
    notes?: string;
    cloudProviderFullName: string;
    replica_url?: string;
    primary_url?: string;
    can_upgrade_to?: string;
    license?: string;
    notifications: DataStoreEmailNotificationsSettings;
    getAutoscaleStorage: Function;
    parameterGroupId: string;
    parameterGroupSync: DbParameterSync;
}

export default class DeploymentsItem implements DeploymentsItemInterface {
    readonly accountId: string;
    readonly availabilityZones?: string[];
    readonly cloudProvider: CloudProvider;
    readonly clusterId: number;
    readonly dataStoreName: string;
    readonly numberOfNodes: number;
    readonly clusterStatus: DataStoreStatus;
    readonly clusterType: string;
    readonly clusterTypeName: string;
    readonly createdAt: string;
    readonly deployProgress: number;
    readonly volumeSize: number;
    readonly volumeType: string;
    readonly highAvailability: boolean;
    readonly volumeIops: number;
    readonly instanceSize: string;
    readonly maintenanceSettings: DataStoreMaintenanceSettings;
    readonly notOperableReason: string;
    readonly operable: boolean;
    readonly cloudRegion: CloudRegion;
    readonly seenAt: string;
    readonly sslEnabled: boolean;
    readonly currentJob: Job;
    readonly tags: string[];
    readonly updatedAt: string;
    readonly dataStoreUuid: string;
    readonly db: Db;
    readonly dbAccount: DbAccount;
    readonly lastSeen: string;
    readonly lastSeenFull: string;
    readonly createdAtFull: string;
    readonly vpc?: Vpc;
    readonly notes?: string;
    readonly cloudProviderFullName: string;
    readonly preferredRegion?: string;
    readonly replica_url?: string;
    readonly primary_url?: string;
    readonly can_upgrade_to?: string;
    readonly license?: string;
    readonly notifications: DataStoreEmailNotificationsSettings;
    readonly spec: DataStoreSpec;
    readonly isCAAvailable: boolean;
    readonly parameterGroupId: string;
    readonly parameterGroupSync: DbParameterSync;

    constructor(
        props: DeploymentsItemClassProps,
        deploymentOptions: DeploymentOptions | undefined
    ) {
        this.accountId = props.account_id;
        this.availabilityZones = props.azs ? props.azs : undefined;
        this.cloudProvider = new CloudProvider({
            code: props.cloud_provider,
            name: deploymentOptions?.getFullName(props.cloud_provider),
            full_name: deploymentOptions?.getFullName(props.cloud_provider),
            regions: [],
            logo: deploymentOptions?.getCloudProviderLogo(props.cloud_provider),
            group: deploymentOptions?.getCloudProviderGroup(
                props.cloud_provider
            ),
        });
        this.dataStoreName = props.cluster_name;
        this.numberOfNodes = props.cluster_size;
        this.clusterStatus = new DataStoreStatus({
            cluster_status: props.cluster_status,
            cluster_status_text: props.cluster_status_text,
        });
        this.clusterType = props.cluster_type;
        this.notes = props.notes ? props.notes : '';
        this.clusterTypeName = props.cluster_type_name;
        this.clusterId = props.cluster_id;
        this.deployProgress = props.deploy_progress;
        this.volumeSize = props.effective_disk_size
            ? props.effective_disk_size
            : props.disk_size;
        this.volumeType = props.disk_type;
        this.highAvailability = props.high_availability;
        this.instanceSize = props.instance_size;
        this.maintenanceSettings = new DataStoreMaintenanceSettings(
            props.maintenance_settings
        );
        this.spec = new DataStoreSpec(props.spec);
        this.notifications = new DataStoreEmailNotificationsSettings(
            props.notifications
        );
        this.currentJob = new Job(props.current_job);
        this.volumeIops = props.iops ? props.iops : 0;
        this.notOperableReason = props.not_operable_reason;
        this.operable = props.operable;
        this.cloudRegion = props.region;
        this.seenAt = props.seen_at || '--';
        this.sslEnabled = props.ssl_enabled;
        this.tags = props.tags;
        this.updatedAt = props.updated_at;
        this.dataStoreUuid = props.uuid;
        this.db = new Db({
            database_vendor: props.database_vendor,
            database_version: props.database_version,
            database_endpoint: props.database_endpoint,
        });
        this.dbAccount = new DbAccount({
            username: props.db_account?.database_username,
            password: props.db_account?.database_password,
            host: props.db_account?.database_host,
            database: props.db_account?.database_database,
            privileges: props.db_account?.database_privileges,
        });
        this.lastSeen = props.seen_at
            ? new FriendlyDateTime({
                  date: props.seen_at,
              }).getFriendlyDate()
            : '--';
        this.lastSeenFull = props.seen_at
            ? new FriendlyDateTime({
                  date: props.seen_at,
              }).getFullDate()
            : '--';
        this.createdAt = new FriendlyDateTime({
            date: props.created_at,
        }).getFriendlyDate();
        this.createdAtFull = new FriendlyDateTime({
            date: props.created_at,
        }).getFullDate();
        this.vpc = props.vpc ? new Vpc(props.vpc) : undefined;
        this.cloudProviderFullName = deploymentOptions?.getFullName(
            props.cloud_provider
        );
        this.preferredRegion = props.preferred_region;
        this.primary_url = props.primary_url;
        this.replica_url = props.replica_url;
        this.can_upgrade_to = props.can_upgrade_to;
        this.license = props.license;
        this.isCAAvailable = props.is_ca_available;
        this.parameterGroupId = props.parameter_group_id;
        this.parameterGroupSync = new DbParameterSync(
            props.parameter_group_sync
        );
    }

    getAutoscaleStorage(): boolean {
        return this.spec.autoscale_storage.enabled;
    }

    getDbIconName(): string {
        return this.db.getIconName();
    }

    getDbVendorName(): string {
        return this.db.getVendorName();
    }

    getDbVendorType(): DatabaseType {
        return this.db.getDatabaseType();
    }

    getDbVendorVersion(): string {
        return this.db.dbVersion;
    }

    getProviderName(): string {
        return this.cloudProvider.code.toUpperCase();
    }

    getCloudProvider(): CloudProvider {
        return this.cloudProvider;
    }

    getCloudProviderCode(): string {
        return this.getCloudProvider().code;
    }

    getFullCloudProviderName(): string {
        if (this.cloudProvider.getFullName())
            return this.cloudProvider.getFullName();
        return 'Unknown';
    }

    getVolumeIops(): number {
        return this.volumeIops;
    }

    getInstanceSize(): string {
        return this.instanceSize;
    }

    getDbEndpoint(): string {
        return this.db.getDbEndpoint();
    }

    isStatusOk(): boolean {
        return this.clusterStatus.isStatusOk();
    }

    isStatusWarning(): boolean {
        return this.clusterStatus.isStatusWarning();
    }

    isStatusError(): boolean {
        return this.clusterStatus.isStatusError();
    }

    isDeploying(): boolean {
        return this.clusterStatus.isDeploying();
    }

    isDeleting(): boolean {
        return this.clusterStatus.isDeleting();
    }

    isUnreachable(): boolean {
        return this.clusterStatus.isUnreachable();
    }

    isUnderMaintenance(): boolean {
        return this.clusterStatus.isUnderMaintenance();
    }

    isAccessible(): boolean {
        return !this.isDeploying() && this.clusterStatus.isDeploymentFailed();
    }

    isOperable(): boolean {
        if (this.clusterStatus.isDeploymentFailed()) return false;
        if (this.isDeploying()) return false;

        return this.operable;
    }

    isMariaDb(): boolean {
        return this.db.isMariadb();
    }

    isPostgreSql(): boolean {
        return this.db.isPostgresql();
    }

    isPercona(): boolean {
        return this.db.isPercona();
    }

    isRedis(): boolean {
        return this.db.isRedis();
    }

    isMSSQL(): boolean {
        return this.db.isMSSQL();
    }

    canScaleNodes(): boolean {
        return this.getClusterType().toLowerCase() !== 'mssql_single';
    }

    canChangeVolumeType(): boolean {
        return (
            !this.db.isRedis() &&
            !this.isEphemeral() &&
            this.getClusterType().toLowerCase() !== 'mssql_single'
        );
    }

    getServiceName(): string {
        if (this.isMariaDb()) return DatabaseType.MYSQL;
        if (this.isPercona()) return DatabaseType.MYSQL;
        if (this.isPostgreSql()) return DatabaseType.POSTGRES;
        if (this.isRedis()) return DatabaseType.REDIS;
        if (this.isMSSQL()) return DatabaseType.MSSQL;
        return DatabaseType.UNKNOWN;
    }

    getDeploymentSizeDescription(): string {
        return this.numberOfNodes === 1 ? 'Development' : 'Production';
    }

    getVpcName(): string {
        return this.vpc?.getName() || '---';
    }

    getVpcCidr(): string {
        return this.vpc?.getCidrIpv4Block() || '---';
    }

    isAws(): boolean {
        return this.cloudProvider.isAws();
    }

    isVMWare(): boolean {
        return this.cloudProvider.isVMWare();
    }

    isVultr(): boolean {
        return this.cloudProvider.isVultr();
    }

    isLintasarta(): boolean {
        return this.cloudProvider.isLintasarta();
    }

    isElastx(): boolean {
        return this.cloudProvider.isElastx();
    }

    isSyseleven(): boolean {
        return this.cloudProvider.isSyseleven();
    }

    isOpenStack(): boolean {
        return (
            this.isLintasarta() ||
            this.isSafespring() ||
            this.isElastx() ||
            this.isSyseleven()
        );
    }

    isSafespring(): boolean {
        return this.cloudProvider.isSafespring();
    }

    isCityCloud(): boolean {
        return this.cloudProvider.isCityCloud();
    }

    isGoogle(): boolean {
        return this.cloudProvider.isGoogle();
    }

    getName(): string {
        return this.dataStoreName;
    }

    getTruncatedName(): string {
        return this.getName().length > 20
            ? `${this.getName().substring(0, 20)}...`
            : this.getName();
    }

    getUUID(): string {
        return this.dataStoreUuid;
    }

    getNumberOfNodes(): number {
        return this.numberOfNodes;
    }

    getDataStoreStatus(): string {
        if (this.isStatusOk()) return 'Available';
        if (this.isDeleting()) return 'Deleting';
        if (this.isDeploying()) return 'Deploying';
        if (this.isStatusError()) return 'Failed';
        return Helpers.capitalizeFirstLetter(this.clusterStatus.status);
    }

    getDataStoreStatusText(): string {
        return this.isDeploying()
            ? `${this.clusterStatus.statusText} (${this.deployProgress}% completed)`
            : this.clusterStatus.statusText;
    }

    getDeploymentProgressText(): string {
        return `${this.deployProgress}%`;
    }

    getMaintenanceDayOfWeek(): number {
        return this.maintenanceSettings.getDayOfWeek();
    }

    getMaintenanceEndHour(): number {
        return this.maintenanceSettings.getEndHour();
    }

    getNotificationsEmailList(): string[] {
        return this.notifications.getEmailsList();
    }

    getMaintenanceStartHour(): number {
        return this.maintenanceSettings.getStartHour();
    }

    isProxySql(): boolean {
        return this.isMariaDb() || this.isPercona();
    }

    getDbAccount(): DbAccount {
        return this.dbAccount;
    }

    isSingleAZ(): boolean {
        return new Set(this.availabilityZones).size <= 1;
    }

    getClusterTypeName(): string {
        if (this.clusterTypeName.includes('Master')) {
            return this.clusterTypeName.replace('Master', 'Primary');
        } else {
            return this.clusterTypeName;
        }
    }

    getNotes(): string | undefined {
        return this.notes;
    }

    getClusterType(): string {
        return this.clusterType;
    }

    getPrimaryUrl(): string | undefined {
        return this.primary_url;
    }

    getReplicaUrl(): string | undefined {
        return this.replica_url;
    }

    getVolumeType(): string {
        if (this.volumeType === '') {
            return CLUSTER_TYPE_EPHEMERAL;
        }
        return this.volumeType;
    }

    isEphemeral(): boolean {
        return this.volumeType === '';
    }

    getVolumeSize(deployOptions: DeploymentOptions | undefined): number {
        const cloudProvider = this.getCloudProviderCode();
        const instanceSizes =
            deployOptions?.instance.instanceSizes[cloudProvider];

        if (this.volumeType === '' && this.volumeSize === 0) {
            const matchOption = instanceSizes.find(
                (option: any) => option.type === this.instanceSize
            );
            if (matchOption) return matchOption.diskSize;
        }
        return this.volumeSize;
    }

    getPrimaryReplicaUrl(node: DbNode | LbNode | Service): any[] {
        const urlArr = [];

        if (
            node?.role === 'slave' ||
            node?.role === 'SECONDARY' ||
            node?.role === 'replica'
        ) {
            urlArr.push(this.replica_url);
        } else {
            urlArr.push(this.primary_url);
        }

        return urlArr;
    }

    getScalingSizes(): number[] {
        switch (this.clusterType.toLowerCase()) {
            case 'galera':
            case 'redis':
                return [1, 3, 5];
            case 'mssql_single':
                return [];
            case 'mssql_ao_async':
                switch (this.license) {
                    case 'standard':
                        return [1, 2];
                    case 'enterprise':
                        return [1, 2, 3, 4, 5];
                    default:
                        return [];
                }
        }

        return [1, 2, 3, 4, 5];
    }

    getNewNodesKind(): string {
        switch (this.clusterType.toLowerCase()) {
            case 'galera':
            case 'redis':
                return 'primary';
            case 'mssql_single':
            case 'mssql_ao_async':
                return '';
        }

        return 'replica';
    }

    hasStorage(): boolean {
        if (this.clusterType.toLowerCase() === 'redis') return false;

        return !!this.getVolumeType();
    }
    getDatastoreCreationData(backupId: string) {
        return {
            clusterName: `${this.dataStoreName}_Copy`,
            numberOfNodes: this.numberOfNodes,
            databaseVendor: this.getDatabaseVendor().toLowerCase(),
            databaseVendorType: this.clusterType.toLowerCase(),
            version: this.db.dbVersion,
            tags: this.tags,
            cloudProvider: this.cloudProvider.code,
            cloudRegion: this.cloudRegion.code,
            instanceSize: this.instanceSize,
            volumeType: this.volumeType,
            volumeSize: this.volumeSize,
            volumeIops: this.volumeType === 'io1' ? this.volumeIops : undefined,
            networkType: this.vpc ? 'private' : 'public',
            haEnabled: this.highAvailability,
            vpcUuid: this.vpc ? this.vpc.id : undefined,
            availabilityZones: this.availabilityZones,
            dataStoreUuid: this.dataStoreUuid,
            notifications: this.notifications,
            backupId,
        };
    }
    getDatabaseVendor(): string {
        if (this.isMariaDb()) return DB_VENDOR_MARIADB;
        if (this.isPercona()) return DB_VENDOR_PERCONA;
        if (this.isPostgreSql()) return DB_VENDOR_POSTGRESQL_V2;
        if (this.isRedis()) return DB_VENDOR_REDIS;
        if (this.isMSSQL()) return DB_VENDOR_MSSQL;
        return DB_VENDOR_UNKNOWN;
    }

    getDatabaseVersion(): string {
        return this.db.dbVersion;
    }

    getCurrentJobs(): Job[] {
        return [this.currentJob];
    }

    getParameterGroupId(): string {
        return this.parameterGroupId;
    }

    isDbParameterGroupSyncd(): boolean {
        return this.parameterGroupSync.sync;
    }
}
