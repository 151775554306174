import AppConfirmDialog from '../../../AppConfirmDialog';
import ProvisionService from '../../../../services/ProvisionService';
import Service from '../../../../types/Service';
import { ToolOutlined } from '@severalnines/frontend_hub/libs/icons';
import CcxIconInfoCircleTwoTone from '../../../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../../../ccx/icons/CcxIconCloseCircleTwoTone';
import { notification } from 'antd';
import useDataStoreJobs from '../../../../core/hooks/useDataStoreJobs';

export default RepairNodeButton;

export type RepairNodeButtonProps = {
    service: Service;
};

function RepairNodeButton({ service }: RepairNodeButtonProps) {
    const { refresh: refreshJobs } = useDataStoreJobs({
        dataStoreUuid: service.getClusterUuid(),
    });

    const handleOk = async () => {
        try {
            await ProvisionService.repairNode({
                dataStoreUuid: service.getClusterUuid(),
                hostUuid: service?.getServiceUuid(),
            });

            refreshJobs();

            notification.open({
                message: 'Repair node',
                description: 'The node will be repaired soon.',
                icon: <CcxIconInfoCircleTwoTone />,
            });
        } catch (e: any) {
            notification.open({
                message: 'Repair node',
                description: `There was an error trying to repair the node. ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
        }
    };
    return (
        <AppConfirmDialog
            label="Repair Node"
            onOk={handleOk}
            stretchedClick={true}
            title="WARNING!"
            content="Are you sure you want to repair this node?"
            actionIcon={<ToolOutlined />}
        />
    );
}
