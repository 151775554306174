import React, { ReactElement, useEffect, useState } from 'react';
import {
    Col,
    Collapse,
    Row,
    Modal,
    notification,
    Space,
    Descriptions,
    Menu,
    Dropdown,
    PageHeader,
} from 'antd';
import useContentProviders from '../../core/hooks/useContentProviders';
import AppLoading from '../AppLoading';
import AppEmpty from '../AppEmpty';
import styles from './VpcPeers.module.less';
import AppTable from '../AppTable';
import AppConfirmDialog from '../AppConfirmDialog';
import { MenuOutlined } from '@ant-design/icons';
import VpcService from '../../services/VpcService';
import AppLabeledIconStatus from '../ccx/common/AppLabeledIconStatus';
import CcxIconCheckCircle from '../ccx/icons/CcxIconCheckCircle';
import CcxIconExclamationCircle from '../ccx/icons/CcxIconExclamationCircle';
import CreateVpcPeerButton from './CreateVpcPeerButton';
import useVpcPeers from '../../core/hooks/useVpcPeers';
import { useHistory, useParams } from 'react-router-dom';
import VpcPeering from '../../types/VpcPeering';
import useVpc from '../../core/hooks/useVpc';
import FriendlyDateTime from '../../types/FriendlyDateTime';
import CopyToClipboardText from '../CopyToClipboardText';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxComponentProps from '../../core/CcxComponent';

interface UrlProps {
    projectUuid: string;
    vpcUuid: string;
}

interface Props extends CcxComponentProps {}

function VpcPeers({ testId = 'VpcPeers' }: Props): ReactElement {
    const history = useHistory<any>();
    const { projectUuid, vpcUuid } = useParams<UrlProps>();
    const { loading: loadingProviders } = useContentProviders();
    const [cloud, setCloud] = useState<string | undefined | any>(undefined);
    const [region, setRegion] = useState<string | undefined>(undefined);

    const {
        vpcPeers,
        loading: loadingPeers,
        loaded: peersLoaded,
        refresh: refreshPeers,
    } = useVpcPeers(cloud, region, [vpcUuid]);
    const { vpc } = useVpc(cloud, region, vpcUuid);
    const [tableData, setTableData] = useState<any>([]);

    const [deleting, setDeleting] = useState<boolean>(false);
    const { Panel } = Collapse;

    useEffect(() => {
        if (history) {
            setCloud(history.location.state.cloud);
            setRegion(history.location.state.region);
        }
    }, [history]);

    useEffect(() => {
        const AUTO_REFRESH_INTERVAL = 10000;
        const interval = setInterval(async () => {
            refreshPeers && (await refreshPeers());
        }, AUTO_REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, [cloud, region]);

    useEffect(() => {
        if (vpcPeers && vpcPeers.length > 0) {
            const data = vpcPeers
                .filter((vpcPeering: VpcPeering) => {
                    return vpcPeering.vpcId === vpcUuid;
                })
                ?.map((vpcPeering: VpcPeering) => {
                    return {
                        ...vpcPeering,
                        vpcCidr: vpcPeering.getCidrIpv4Block(),
                        peerRegion: vpcPeering?.targetCloudRegion,
                        peerCidr: vpcPeering.getCidrIpv4Block() || '---',
                        isStatusOk: vpcPeering.isStatusOk(),
                        isStatusError: vpcPeering.isStatusError(),
                        isStatusWarning: vpcPeering.isStatusWarning(),
                        isStatusDeleted: vpcPeering.isStatusDeleted(),
                        isStatusDeleting: vpcPeering.isStatusDeleting(),
                        isStatusPending: vpcPeering.isStatusPending(),
                        // @todo wait for backend, expiration_date is missing in response atm
                        // expirationTime: vpcPeering.getExpirationTime(),
                        statusMessage: vpcPeering.getStatusMessage(),
                        vpcPeeringConnectionId: vpcPeering.id,
                        expandableRowContent: (
                            <Collapse
                                defaultActiveKey={['peeringInfo']}
                                className={styles.ExpandableRowContent}
                            >
                                <Panel
                                    header="Peering Information"
                                    key="peeringInfo"
                                >
                                    <Descriptions>
                                        <Descriptions.Item
                                            label="AWS Owner Id"
                                            className={
                                                styles.VpcPeersDescriptionItems
                                            }
                                        >
                                            <CopyToClipboardText
                                                text={
                                                    vpcPeering.targetCloudUserId
                                                }
                                            />
                                        </Descriptions.Item>

                                        <Descriptions.Item
                                            label="Peering VPC Id"
                                            className={
                                                styles.VpcPeersDescriptionItems
                                            }
                                        >
                                            <CopyToClipboardText
                                                text={
                                                    vpcPeering.targetCloudVpcId
                                                }
                                            />
                                        </Descriptions.Item>

                                        <Descriptions.Item
                                            label="Peering Connection Id"
                                            className={
                                                styles.VpcPeersDescriptionItems
                                            }
                                        >
                                            <CopyToClipboardText
                                                text={vpcPeering.id}
                                            />
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Panel>
                            </Collapse>
                        ),
                    };
                });
            setTableData(data);
        }
    }, [vpcPeers]);

    function handleMenuOptionClick(e: any) {
        // delete
        if (e.key === 'delete') {
            // showConfirm();
        }

        // viewPeerings
        if (e.key === 'viewPeerings') {
            // showConfirm();
        }

        // createPeering
        if (e.key === 'createPeering') {
            // showConfirm();
        }
    }

    const columns = [
        {
            title: 'Region',
            key: 'region',
            dataIndex: 'peerRegion',
        },
        {
            title: 'CIDR',
            key: 'peerCidr',
            dataIndex: 'peerCidr',
        },
        {
            title: 'Status',
            key: 'status',
            render: (value: string, record: any) => {
                let statusClassName = undefined;
                let statusIcon = undefined;
                if (record?.isStatusOk) {
                    // ok
                    statusClassName = styles.VpcPeersStatusOk;
                    statusIcon = <CcxIconCheckCircle />;
                } else if (record?.isStatusError) {
                    // error
                    statusClassName = styles.VpcPeersStatusError;
                    statusIcon = <CcxIconExclamationCircle />;
                } else if (record?.isStatusWarning) {
                    // error
                    statusClassName = styles.VpcPeersStatusWarning;
                    statusIcon = <CcxIconExclamationCircle />;
                }
                return (
                    <div>
                        <AppLabeledIconStatus
                            label={record?.statusMessage?.toUpperCase()}
                            icon={statusIcon}
                            className={statusClassName}
                        />
                        {record?.isStatusPending ? (
                            <em className={styles.VpcPeersExpirationTime}>
                                Peering request expires{' '}
                                {new FriendlyDateTime({
                                    date: record?.expirationTime,
                                }).getFullDate()}
                            </em>
                        ) : null}
                    </div>
                );
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 85,
            render: (text: string, record: any) => {
                const onConfirmDelete = () => {
                    return new Promise(async (resolve, reject) => {
                        setDeleting(true);

                        // if (deployment) {
                        notification.open({
                            message: 'Remove Peering Connection',
                            description:
                                'The selected peering connection will be removed soon.',
                            icon: <CcxIconInfoCircleTwoTone />,
                        });

                        Modal.destroyAll();

                        try {
                            if (!record?.vpcId) {
                                throw new Error('no VPC ID has provided');
                            }
                            const data = {
                                vpcId: record?.vpcId,
                                cloud,
                                region,
                                peeringId: record.id,
                            };
                            await VpcService.deletePeer(data);

                            refreshPeers && (await refreshPeers());

                            notification.open({
                                message: 'Remove Peering Connection',
                                description:
                                    'Peering connection successfully removed.',
                                icon: <CcxIconCheckCircle />,
                            });

                            setDeleting(false);
                            resolve(null);
                        } catch (e) {
                            notification.open({
                                message: 'Remove Peering Connection',
                                description: `There was an error removing the peering connection. ${e}`,
                                icon: (
                                    <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                                ),
                            });

                            console.error(e);

                            setDeleting(false);
                            reject();
                        }
                    }).catch(() => console.log('Oops errors!'));
                };

                const menu = (
                    <Menu onClick={handleMenuOptionClick}>
                        <Menu.Item key="delete">
                            <AppConfirmDialog
                                critical={true}
                                onOk={onConfirmDelete}
                                label="Delete Peering Connection"
                                content="The selected peering connection will be removed."
                            />
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <div className={styles.VpcPeersRowDropdownMenu}>
                        {record.isStatusDeleted ||
                        record.isStatusDeleting ? null : (
                            <Dropdown overlay={menu} trigger={['click']}>
                                <MenuOutlined />
                            </Dropdown>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <section className={styles.VpcPeers} data-testid={testId}>
            <Row>
                <Col span={8}>
                    <PageHeader
                        onBack={() => {
                            history.push(`/projects/${projectUuid}/vpcs`);
                        }}
                        title="Back to VPCs"
                    />
                </Col>
                <Col span={8} offset={8} className={styles.VpcPeersButtons}>
                    <Space>
                        {/* <Button
              onClick={() => {
                history.push(
                  `/projects/${projectUuid}/vpcs/${vpcUuid}/peerings`
                );
              }}
            >
              Show all peerings
            </Button> */}
                        <CreateVpcPeerButton
                            onSuccess={refreshPeers}
                            cloud={cloud}
                            region={region}
                            selectedVpcUuid={vpc?.id}
                        />
                    </Space>
                </Col>
            </Row>
            <div className={styles.VpcPeersContent}>
                {loadingProviders || (loadingPeers && !peersLoaded) ? (
                    <AppLoading />
                ) : null}
                {(!vpcPeers || vpcPeers.length === 0) &&
                !loadingProviders &&
                !loadingPeers ? (
                    <AppEmpty message="No VPC peerings have been created yet on the selected region." />
                ) : null}
                {!loadingProviders &&
                (!loadingPeers || peersLoaded) &&
                vpcPeers &&
                vpcPeers?.length > 0 ? (
                    <div className={styles.VpcPeersResults}>
                        <Row className={styles.VpcPeersButtons}>
                            <Col>
                                <Space>
                                    {/* <AddFirewallRule onSuccess={refresh} uuid={uuid} /> */}
                                </Space>
                            </Col>
                        </Row>

                        <Collapse
                            bordered={false}
                            defaultActiveKey={['peerings']}
                            className={styles.VpcPeersCollapse}
                        >
                            <Panel
                                header={`Peering connections for ${vpc?.getName()} (${vpc?.getCidrIpv4Block()})`}
                                key="peerings"
                            >
                                <AppTable
                                    columns={columns}
                                    data={tableData}
                                    rowKey="vpcPeeringConnectionId"
                                    pagination={{
                                        hideOnSinglePage: true,
                                    }}
                                />
                            </Panel>
                        </Collapse>
                    </div>
                ) : null}
            </div>
        </section>
    );
}

export default VpcPeers;
