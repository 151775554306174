import CcxContextualMenuIcon from '../ccx/icons/CcxContextualMenuIcon';
import { Button, Dropdown, Menu } from 'antd';
import DeploymentsItem from '../../types/DeploymentsItem';
import DbUser from '../../types/DbUser';
import UpdateDbUserDrawer from './UpdateDbUserDrawer';
import AppConfirmDialog from '../AppConfirmDialog';
import React from 'react';
import {
    DeleteOutlined,
    EditOutlined,
} from '@severalnines/frontend_hub/libs/icons';

type UpdateDbUserActionsMenuProps = {
    disabled?: boolean;
    deployment: DeploymentsItem;
    user: DbUser;
    onConfirmDelete: () => void;
    uuid: string;
    dbUsersRefresh: Function;
    databases?: any | undefined;
};
export default function UpdateDbUserActionsMenu({
    disabled,
    deployment,
    user,
    onConfirmDelete,
    uuid,
    dbUsersRefresh,
    databases,
}: UpdateDbUserActionsMenuProps) {
    const menu = (
        <Menu>
            <Menu.Item key="edit" icon={<EditOutlined />}>
                <UpdateDbUserDrawer
                    currentDeployment={deployment}
                    user={user}
                    onSuccess={dbUsersRefresh}
                    uuid={uuid}
                    databases={databases}
                />
                Update
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="delete" danger={true} icon={<DeleteOutlined />}>
                <AppConfirmDialog
                    stretchedClick={true}
                    critical={true}
                    onOk={onConfirmDelete}
                    inLined={true}
                    content="The account will be removed and will not be able to connect to the database."
                />
            </Menu.Item>
        </Menu>
    );
    return (
        <Dropdown
            overlay={menu}
            disabled={disabled}
            placement="bottomRight"
            trigger={['click']}
        >
            <Button icon={<CcxContextualMenuIcon />} />
        </Dropdown>
    );
}
