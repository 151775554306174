import { UserAddOutlined } from '@severalnines/frontend_hub/libs/icons';
import React from 'react';
import CcxComponentProps from '../../../core/CcxComponent';

interface Props extends CcxComponentProps {}

const CcxIconUserAddOutlined = ({ testId }: Props) => {
    return <UserAddOutlined data-testid={testId || 'CcxIconUserAddOutlined'} />;
};

export default CcxIconUserAddOutlined;
