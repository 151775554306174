import React, { ReactElement, useState } from 'react';
import { Modal, Space } from 'antd';
import { ExclamationCircleOutlined } from '@severalnines/frontend_hub/libs/icons';
import styles from './AppConfirmDialog.module.less';
import { Input, Alert, message, Form, Tooltip } from 'antd';
import CcxComponentProps from '../core/CcxComponent';
import classNames from 'classnames';
import StretchedClick from './common/StretchedClick';

interface Props extends CcxComponentProps {
    icon?: any;
    title?: any;
    alertTitle?: any;
    content: string;
    onOk?: Function;
    onCancel?: Function;
    stretchedClick?: boolean;
    label?: React.ReactNode;
    tooltip?: React.ReactNode;
    actionIcon?: any;
    confirmPhrase?: string;
    confirmHintText?: string;
    confirmPlaceholder?: string;
    disabled?: boolean;
    critical?: boolean;
    inLined?: boolean;
}

function AppConfirmDialog({
    icon,
    title,
    alertTitle,
    content,
    onOk,
    onCancel,
    stretchedClick = false,
    label,
    tooltip,
    actionIcon,
    confirmPhrase,
    confirmHintText,
    confirmPlaceholder,
    disabled,
    testId = 'AppConfirmDialog',
    critical,
    inLined = false,
}: Props): ReactElement {
    const { confirm } = Modal;
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();

    const handleClickIcon = () => {
        if (disabled) {
            return;
        }

        if (!confirmPhrase) {
            confirm({
                title: title || `Are you sure?`,
                icon: icon ?? (
                    <ExclamationCircleOutlined
                        className={styles.AppConfirmationIcon}
                    />
                ),
                className: styles.AppConfirmationModal,
                content,
                onOk() {
                    onOk && onOk();
                },
                onCancel() {
                    onCancel && onCancel();
                },
            });
        } else {
            setVisible(true);
        }
    };

    const verifyMatch = (value: string) => {
        return value === confirmPhrase;
    };

    const handleOk = () => {
        const phrase = form.getFieldValue('confirmation');
        if (verifyMatch(phrase)) {
            setVisible(false);
            form.setFieldsValue({ confirmation: '' });
            onOk && onOk();
        } else {
            message.error(
                'Incorrect confirmation. Did you enter the correct value?'
            );
        }
    };

    const handleCancel = () => {
        setVisible(false);
        onCancel && onCancel();
    };

    const handleKeyUpEvent = (event: any) => {
        if (event.key === 'Enter') {
            handleOk();
        }
    };

    return (
        <>
            {stretchedClick && <StretchedClick handleClick={handleClickIcon} />}

            {inLined ? (
                <span
                    style={{ textAlign: 'center' }}
                    onClick={() => {
                        if (stretchedClick) return;
                        handleClickIcon();
                    }}
                >
                    Delete
                </span>
            ) : (
                <div
                    className={classNames(styles.AppConfirmDialog, {
                        [styles.AppConfirmDialogDisabled]: disabled,
                        [styles.AppConfirmDialogCritical]: critical,
                    })}
                    onClick={() => {
                        if (stretchedClick) return;
                        handleClickIcon();
                    }}
                    data-testid={`${testId}IconLabel`}
                >
                    {tooltip ? (
                        <Tooltip title={tooltip}>
                            <Space size={5}>
                                {actionIcon}
                                <span>{label}</span>
                            </Space>
                        </Tooltip>
                    ) : label ? (
                        <Space size={5}>
                            {actionIcon}
                            <span>{label}</span>
                        </Space>
                    ) : (
                        <Space>{actionIcon}</Space>
                    )}
                </div>
            )}

            {visible ? (
                <Modal
                    title={title || 'Are you sure?'}
                    open={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    data-testid={`${testId}Modal`}
                    destroyOnClose={true}
                >
                    <Alert
                        data-testid={`${testId}Alert`}
                        message={
                            <div
                                className={styles.AppConfirmDialogAlertMessage}
                                data-testid="AppConfirmDialogAlertMessage"
                            >
                                {alertTitle || 'Warning'}
                            </div>
                        }
                        description={
                            <div
                                className={
                                    styles.AppConfirmDialogAlertDescription
                                }
                                data-testid="AppConfirmDialogAlertDescription"
                            >
                                {content}
                            </div>
                        }
                        type="warning"
                        showIcon={true}
                    />
                    <br />
                    <p data-testid={`${testId}AlertConfirmText`}>
                        {confirmHintText}
                    </p>
                    <Form
                        layout="horizontal"
                        form={form}
                        scrollToFirstError={true}
                        onKeyUp={handleKeyUpEvent}
                    >
                        <Form.Item name="confirmation" noStyle={true}>
                            <Input
                                autoComplete="off"
                                className={styles.AppConfirmationInput}
                                data-testid={`${testId}AlertConfirmInput`}
                                placeholder={confirmPlaceholder}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            ) : null}
        </>
    );
}

export default AppConfirmDialog;
