import CcxContextualMenuIcon from '../ccx/icons/CcxContextualMenuIcon';
import { Button, Dropdown, Menu } from 'antd';
import DeploymentsItem from '../../types/DeploymentsItem';
import BackupsListRestoreButton from './BackupsListRestoreButton';
import Restore from '../../types/Restore';
import CcxIconPlusCircleOutlined from '../ccx/icons/CcxIconPlusCircleOutlined';
import BackupsListCreateDatastoreButton from './BackupsListCreateDatastoreButton';
import styles from './BackupsListCreateDatastoreButton.module.less';
import { Draft } from '@reduxjs/toolkit';

type BackupsListActionsMenuProps = {
    record: any;
    disabled?: boolean;
    currentRestore?: Restore;
    deployment: Draft<DeploymentsItem>;
    setRestoreObj: (obj: any) => void;
    setOpenRestore: (value: boolean) => void;
};

export default function BackupsListActionsMenu({
    record,
    disabled,
    deployment,
    setRestoreObj,
    setOpenRestore,
    currentRestore,
}: BackupsListActionsMenuProps) {
    const menu = (
        <Menu>
            {(deployment.isMariaDb() ||
                deployment.isPostgreSql() ||
                deployment.isPercona()) && (
                <Menu.Item key="createdatastore">
                    <BackupsListCreateDatastoreButton
                        deployment={deployment}
                        record={record}
                        actionIcon={
                            <div
                                className={
                                    styles.BackupActionCreateDatastoreButton
                                }
                            >
                                <CcxIconPlusCircleOutlined /> Create Datastore
                            </div>
                        }
                    />
                </Menu.Item>
            )}
            <Menu.Item key="restore">
                <BackupsListRestoreButton
                    record={record}
                    deployment={deployment}
                    setRestoreObj={setRestoreObj}
                    setOpenRestore={setOpenRestore}
                    currentRestore={currentRestore}
                />
            </Menu.Item>
        </Menu>
    );
    // @todo create common component for action menu and maybe reuse ActionMenu from bar-frontend-components
    return (
        <Dropdown
            overlay={menu}
            disabled={disabled}
            placement="bottomRight"
            trigger={['click']}
        >
            <Button icon={<CcxContextualMenuIcon />} />
        </Dropdown>
    );
}
