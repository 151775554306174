import ApiService from './ApiService';
import { API_BILLING } from '../core/CcxEnv';
import Address from '../types/Address';
import BillingUserData from '../types/BillingUserData';
import Subscription from '../types/Subscription';
import Card from '../types/Card';
import SubscriptionBillingUser from '../types/SubscriptionBillingUser';
import Coupon from '../types/Coupon';
import Invoice from '../types/Invoice';
import SetupIntent from '../types/SetupIntent';

// constants
const API_URL = API_BILLING;

type CreateUserSubscriptionParams = {
    address: Address;
    cardToken?: string;
    paymentMethod?: string;
    coupon?: string;
    userData: BillingUserData;
};

export default class BillingService extends ApiService {
    /**
     * Check if the current user in session has a valid subscription.
     */
    static async checkUserSubscription(): Promise<Subscription> {
        const result = await this.request('GET', `subscription`);
        return new Subscription(result);
    }

    /**
     * Create billing user for current user in session.
     * @param {Address} address
     * @param {string} cardToken
     * @param {string} coupon
     * @param {BillingUserData} userData
     */
    static async createBillingUser({
        address,
        coupon,
        userData,
    }: CreateUserSubscriptionParams): Promise<any> {
        const requestData = {
            data: {
                address: {
                    line_1: address.line1,
                    line_2: address.line2,
                    city: address.city,
                    country: address.country,
                    postal_code: address.postalCode,
                    state: address.state,
                },
                coupon,
                user_data: {
                    first_name: userData.firstName,
                    last_name: userData.lastName,
                    company_name: userData.companyName,
                    eu_vat_id: userData.euVatId,
                },
            },
        };
        return await this.request('POST', `users`, requestData);
    }

    /**
     * Create a subscription for current user in session.
     * @param {Address} address
     * @param {string} cardToken
     * @param {string} coupon
     * @param {BillingUserData} userData
     */
    static async createUserSubscription({
        address,
        paymentMethod,
        coupon,
        userData,
    }: CreateUserSubscriptionParams): Promise<any> {
        const requestData = {
            data: {
                address: {
                    line_1: address.line1,
                    line_2: address.line2,
                    city: address.city,
                    country: address.country,
                    postal_code: address.postalCode,
                    state: address.state,
                },
                payment_method: paymentMethod,
                coupon,
                user_data: {
                    first_name: userData.firstName,
                    last_name: userData.lastName,
                    company_name: userData.companyName,
                    eu_vat_id: userData.euVatId,
                },
            },
        };
        return await this.request('POST', `subscription`, requestData);
    }

    /**
     * Edit a subscription for current user in session.
     * @param {Address} address
     * @param {BillingUserData} userData
     *
     */
    static async editUserSubscription(
        { address, userData }: CreateUserSubscriptionParams,
        uuid: string
    ): Promise<any> {
        const requestData = {
            data: {
                address: {
                    line_1: address.line1,
                    line_2: address.line2,
                    city: address.city,
                    country: address.country,
                    postal_code: address.postalCode,
                    state: address.state,
                },
                user_data: {
                    first_name: userData.firstName,
                    last_name: userData.lastName,
                    company_name: userData.companyName,
                    eu_vat_id: userData.euVatId,
                },
            },
        };
        return await this.request('PUT', `billing_users/${uuid}`, requestData);
    }

    /**
     * End the subscription of the current user in session.
     */
    static async deleteUserSubscription(): Promise<any> {
        return await this.request('DELETE', `subscription`);
    }

    /**
     * Get a list of the cards for the current user in session.
     */
    static async getCards(): Promise<any> {
        const result = await this.request('GET', `cards`);
        return {
            cards: result?.cards?.map((r: any) => new Card(r)),
            billingUser: new SubscriptionBillingUser(result?.billing_user),
        };
    }

    /**
     * Add a new card to the current user in session.
     * @param {string} cardToken
     */
    static async addCard(cardToken?: string): Promise<any> {
        const requestData = {
            data: {
                token: cardToken,
            },
        };
        return await this.request('POST', `cards`, requestData);
    }

    /**
     * Add a setup intents for the current user in session.
     * @param {string} cardToken
     */
    static async createBillingIntent(cardToken?: string): Promise<any> {
        try {
            const requestData = {
                data: {
                    token: cardToken,
                },
            };
            const result = await this.request(
                'POST',
                `setupintents`,
                requestData
            );

            return new SetupIntent(result);
        } catch (e) {
            throw new Error('There was an error adding your Card. ' + e);
        }
    }

    /**
     * Removes a card for the current user in session.
     * @param {string} cardId
     */
    static async deleteCard(cardId: string): Promise<any> {
        return await this.request('DELETE', `cards/${cardId}`);
    }

    /**
     * Check if the current user in session has a valid subscription.
     * @param {string} coupon
     */
    static async checkCoupon(coupon: string): Promise<any> {
        const requestData = {
            data: {
                coupon,
            },
        };
        try {
            const result = await this.request(
                'POST',
                `coupons/check`,
                requestData
            );
            return {
                coupon: new Coupon(result?.coupon),
            };
        } catch (e) {
            return {
                coupon: null,
            };
        }
    }

    /**
     * Get the coupon for the current user in session.
     */
    static async getCoupons(): Promise<any> {
        const result = await this.request('GET', `coupons/customer`);

        return result;
    }

    /**
     * Add a new coupon to the current user in session.
     * @param {string} coupon
     */
    static async addCoupon(coupon?: string): Promise<any> {
        const requestData = {
            data: {
                coupon,
            },
        };
        return await this.request('POST', `coupons/customer`, requestData);
    }

    /**
     * delete coupon for the current user in session.
     */
    static async deleteCoupon(): Promise<any> {
        return await this.request('DELETE', `coupons/customer`);
    }

    /**
     * Set a card as the default payment method for the current user in session.
     * @param {string} cardId
     */
    static async setDefaultCard(cardId: string): Promise<any> {
        return await this.request('PATCH', `cards/${cardId}/set-default`);
    }

    /**
     * Get a list of the invoices.
     */
    static async getInvoices(): Promise<Invoice[]> {
        const result = await this.request('GET', `invoices`);

        return result?.invoices?.map((i: any) => new Invoice(i));
    }

    /**
     * Get next invoice.
     */
    static async getNextInvoice(): Promise<Invoice> {
        const result = await this.request('GET', `invoices/next`);

        return new Invoice(result.invoice);
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
