import { ReactElement } from 'react';
import styles from './AppEmpty.module.less';
import { Empty } from 'antd';
import CcxComponentProps from '../core/CcxComponent';
import EmptyState from '@severalnines/frontend_hub/libs/icons/src/lib/ilustrations/EmptyState';
import { getThemeIllustrationColor } from '../core/helpers';

interface Props extends CcxComponentProps {
    message?: string | ReactElement;
    children?: any;
    defaultImage?: boolean;
}

function AppEmpty({
    message,
    children,
    testId = 'AppEmpty',
    defaultImage = false,
}: Props) {
    return (
        <Empty
            data-testid={testId}
            className={styles.AppEmpty}
            description={
                <span
                    className={styles.AppEmptyMessage}
                    data-testid={`${testId}Description`}
                >
                    {message ? message : 'No data to display.'}
                </span>
            }
            image={
                <EmptyState
                    style={{
                        width: '100px',
                        color: getThemeIllustrationColor(),
                    }}
                />
            }
        >
            {children}
        </Empty>
    );
}

export default AppEmpty;
