import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Button } from 'antd';
import React, { useState } from 'react';
import CcxComponentProps from '../../core/CcxComponent';
import { billingDisabled, StripePK } from '../../core/CcxEnv';
import useCards from '../../core/hooks/useCards';
import Card from '../../types/Card';
import AddCard from './AddCard';
import styles from './PaymentMethodModal.module.less';
import AppLoading from '../AppLoading';
import AppEmpty from '../AppEmpty';
import { PaymentCard } from './PaymentCard';
import { Modal } from 'antd';
import { Row } from 'antd';
import { Col } from 'antd';
import { Form } from 'antd';
import AppForm from '../ccx/common/AppForm';
import { FormInstance } from 'antd/lib/form';
import { message } from 'antd';
import { PromoCodeCard } from './PromoCodeCard';
import useCoupons from '../../core/hooks/useCoupons';
import BillingService from '../../services/BillingService';
import { notification } from 'antd';
import CcxIconCheckCircle from '../ccx/icons/CcxIconCheckCircle';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';

interface Props extends CcxComponentProps {
    label: string;
    icon?: any;
}

function PaymentMethodModal({
    label,
    testId = 'PaymentMethodModal',
    icon,
}: Props) {
    const [promoForm] = Form.useForm();

    const [visible, setVisible] = useState(false);

    const [promoModal, setPromoModal] = useState(false);
    const [errorFields, setErrorFields] = useState([]);

    const { cards, refresh, loading } = useCards();
    const {
        coupon,
        refresh: refreshCoupon,
        loading: loadingCoupon,
    } = useCoupons();

    const stripePromise = !billingDisabled ? loadStripe(StripePK) : null;

    const fields = {
        step1: [
            {
                name: ['code'],
                testId: 'PaymentMethodModalPromoCode',
                required: true,
                label: 'Code',
                placeholder: 'Enter your promo code',
                type: 'input',
            },
        ],
    };

    const showModal = () => {
        setVisible(true);
    };

    const handleClose = () => {
        setVisible(false);
    };

    const validateForm = async (form: FormInstance) => {
        try {
            const a = await form.validateFields();
            return true;
        } catch (e: any) {
            setErrorFields(e.errorFields);
            message.error(
                'One or more fields have errors. Please double check and try again.'
            );
            return false;
        }
    };

    const handlePromoAdd = async () => {
        if (coupon) {
            notification.open({
                message: 'You cant add more promo codes',
                description: `Currently you have an active promo code. To add different promo code, remove the current one.`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
            return;
        }

        const stepValid = await validateForm(promoForm);

        if (stepValid) {
            const formCoupon = promoForm.getFieldValue('code');

            try {
                await BillingService.addCoupon(formCoupon);

                notification.open({
                    message: 'Code added successfully',
                    icon: <CcxIconCheckCircle />,
                });

                refreshCoupon();
                setPromoModal(false);

                promoForm.resetFields();
            } catch (e) {
                notification.open({
                    message:
                        cards && cards?.length !== 0
                            ? 'Invalid promo code'
                            : 'Credit card should be previously added before adding a Promo code',
                    description: `The code you entered is incorrect or invalid. ${e}`,
                    icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                });

                throw e;
            }
        }
    };

    const handleCancel = () => {
        setPromoModal(false);
        promoForm.resetFields();
        setErrorFields([]);
    };

    return (
        <>
            <Button type="link" onClick={showModal}>
                {icon}
                {label}
            </Button>
            <Modal
                title={'Payment methods'}
                open={visible}
                data-testid={`${testId}Modal`}
                width={800}
                onCancel={handleClose}
                footer={
                    <Row justify="end">
                        <Col>
                            <Button
                                data-testid={`${testId}CancelButton`}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <div
                    data-testid={`${testId}Title`}
                    className={styles.PaymentMethodModalTitle}
                >
                    <h4 data-testid={`${testId}TitleCards`}>
                        <strong>Credit/debit card</strong>
                    </h4>

                    <div>
                        <Button
                            type="link"
                            onClick={() => {
                                setPromoModal(true);
                            }}
                        >
                            Add promo code
                        </Button>

                        {stripePromise && (
                            <Elements stripe={stripePromise}>
                                <AddCard
                                    onSuccess={refresh}
                                    buttonType="link"
                                />
                            </Elements>
                        )}
                    </div>
                </div>
                {loading ? (
                    <AppLoading />
                ) : cards && cards.length > 0 ? (
                    <div className={styles.PaymentMethodModalCards}>
                        {cards.map((card: Card) => {
                            return (
                                <PaymentCard
                                    card={card}
                                    key={`${card.id}card`}
                                    refresh={refresh}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <AppEmpty
                        message="No Cards found"
                        testId={`${testId}CardsNoData`}
                    />
                )}

                <div
                    data-testid={`${testId}PromoTitle`}
                    className={styles.PaymentMethodModalCouponTitle}
                >
                    <h4 data-testid={`${testId}TitlePromo`}>
                        <strong>Promo codes</strong>
                    </h4>
                </div>
                {loadingCoupon ? (
                    <AppLoading />
                ) : coupon ? (
                    <div>
                        <PromoCodeCard
                            key={`${coupon.name}coupon`}
                            coupon={coupon}
                            refresh={refreshCoupon}
                        />
                    </div>
                ) : (
                    <AppEmpty
                        message="No Promo codes found"
                        testId={`${testId}PromoNoData`}
                    />
                )}
            </Modal>
            <Modal
                title={'Add promo code'}
                open={promoModal}
                data-testid={`${testId}Modal`}
                onCancel={handleCancel}
                width={350}
                footer={
                    <Row justify="end">
                        <Col>
                            <Button
                                onClick={handlePromoAdd}
                                data-testid={`${testId}SubmitButton`}
                                type="primary"
                            >
                                Add
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Form
                    layout="vertical"
                    form={promoForm}
                    scrollToFirstError={true}
                >
                    <AppForm
                        fields={fields.step1}
                        onPressEnter={handlePromoAdd}
                        errorFields={errorFields}
                    />
                </Form>
            </Modal>
        </>
    );
}

export default PaymentMethodModal;
