export type DbParameterValue = {
    value: string;
    description: string;
    type: 'number' | 'text' | 'boolean' | 'select';
    validation_options?: string;
    default_value: string;
    min?: string;
    max?: string;
};

export type DbParameterDefaultsProps = {
    parameters: {
        [key: string]: DbParameterValue;
    };
    status: 'pending' | string;
    updated_at: string;
};

export interface DBParameterDefaultsInterface {
    parameters: { [key: string]: DbParameterValue };
    status: string;
    updatedAt: string;
}

export class DBParameterDefaults implements DBParameterDefaultsInterface {
    readonly parameters: { [key: string]: DbParameterValue };
    readonly status: string;
    readonly updatedAt: string;

    constructor(props: DbParameterDefaultsProps) {
        this.parameters = props.parameters;
        this.status = props.status;
        this.updatedAt = props.updated_at;
    }

    getParameter(key: string): DbParameterValue | undefined {
        return this.parameters[key];
    }

    getAllParameters(): {
        [key: string]: Omit<DbParameterValue, 'validation_options'> & {
            min?: string;
            max?: string;
        };
    } {
        let result: {
            [key: string]: Omit<DbParameterValue, 'validation_options'> & {
                min?: string;
                max?: string;
            };
        } = {};
        Object.entries(this.parameters).forEach(([key, param]) => {
            const { validation_options, ...rest } = param;
            result[key] = {
                ...rest,
            };

            if (validation_options) {
                const matches = validation_options.match(/min=(\d+),max=(\d+)/);
                if (matches) {
                    result[key].min = matches[1];
                    result[key].max = matches[2];
                }
            }
        });
        return result;
    }

    getParameterKeys(): string[] {
        return Object.keys(this.parameters);
    }
}
