import React from 'react';
import { Row, Col, Alert, Button } from 'antd';
import {
    EditOutlined,
    FileTextOutlined,
    LinkOutlined,
} from '@ant-design/icons';
import styles from './DatastoreSettingsDbParameters.module.less';
import DotLabelStatus from '../../../ccx/common/DotLabelStatus';

interface ParameterGroupDisplayProps {
    readonly selectedGroup: any;
    readonly isSyncing: boolean;
    readonly createLegacyGroup: Function;
    readonly setVisible: (visible: boolean) => void;
    readonly syncGroup: (groupId: string) => void;
    readonly mapDbParametersGroup: () => any;
    readonly dataStore: any;
    readonly loading?: boolean;
    readonly setIsDbParameterEditable: (visible: boolean) => void;
}

const ParameterGroupDisplay: React.FC<ParameterGroupDisplayProps> = ({
    createLegacyGroup,
    selectedGroup,
    setVisible,
    isSyncing,
    syncGroup,
    mapDbParametersGroup,
    dataStore,
    loading,
    setIsDbParameterEditable,
}) => {
    const [isSynced] = React.useState<boolean>(
        dataStore?.isDbParameterGroupSyncd()
    );

    return selectedGroup ? (
        <Row justify={'space-around'} className={styles.DbParametersGroupRow}>
            <Col xs={24} sm={17}>
                <Row className={styles.DBparametersGroupCard}>
                    <Col
                        span={2}
                        className={`${styles.DbParametersSelfCenter} ${styles.DatastoreDbParametersIcon}`}
                    >
                        <FileTextOutlined />
                    </Col>
                    <Col span={15} className={styles.DbParametersDetails}>
                        <p>
                            {selectedGroup.name}{' '}
                            <Button
                                type="link"
                                className={styles.DbParametersEditButton}
                                onClick={() => setIsDbParameterEditable(true)}
                            >
                                <EditOutlined />
                            </Button>
                        </p>
                        <strong>
                            {selectedGroup.databaseVersion}{' '}
                            {selectedGroup.databaseVendor}
                        </strong>
                    </Col>
                    <Col
                        span={7}
                        className={`${styles.DbParametersSelfCenter} ${styles.DbParametersEnd}`}
                    >
                        <DotLabelStatus
                            type={
                                isSyncing ? 'info' : isSynced ? 'ok' : 'error'
                            }
                            label={
                                isSyncing
                                    ? 'Pending'
                                    : isSynced
                                    ? 'Synced'
                                    : 'Sync failed'
                            }
                            glow={true}
                        />
                    </Col>
                </Row>
            </Col>
            <Col
                xs={24}
                sm={7}
                className={`${styles.DbParametersSelfCenter} ${styles.DbParametersActions}`}
            >
                <Button
                    style={{ marginRight: '10px' }}
                    loading={loading}
                    onClick={() => syncGroup(selectedGroup.uuid)}
                >
                    <LinkOutlined /> Re-sync group
                </Button>
                {mapDbParametersGroup().length > 0 && (
                    <Button loading={loading} onClick={() => setVisible(true)}>
                        <EditOutlined /> Change group
                    </Button>
                )}
            </Col>
        </Row>
    ) : (
        <Alert
            message={
                <strong>You have no parameter group for this data store</strong>
            }
            showIcon={true}
            className={styles.ParameterGroupAlert}
            description="To edit the default database parameter values, first create a parameter group and assign it to the data store, or select an existing one."
            type="info"
            action={
                <Row gutter={16} className={styles.ParameterGroupAlertAction}>
                    {mapDbParametersGroup().length > 0 && (
                        <Col>
                            <Button onClick={() => setVisible(true)}>
                                Assign existing group
                            </Button>
                        </Col>
                    )}
                    <Col>
                        <Button
                            type="primary"
                            onClick={() => createLegacyGroup()}
                        >
                            Create a group
                        </Button>
                    </Col>
                </Row>
            }
        />
    );
};

export default ParameterGroupDisplay;
